import React, { useRef } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { useSearchBox } from 'react-instantsearch-hooks-web';
import { FaSearch } from "react-icons/fa";

export const UserTableSearchBar = (props) => {
  const { refine, clear } = useSearchBox();
  const inputEl = useRef<HTMLInputElement>(null);

  const clearText = () => {
    clear();
    if(inputEl.current !== null){
        inputEl.current.value = "";
      }
  };

  return (
    <div className="input-wrapper">
      <FaSearch className="input-icon" />
      <input
        className="forumSearchBox"
        type="text"
        placeholder="Search by display name, email, or UID"
        onChange={(e) => {
          refine(e.target.value);
        }}
        ref={inputEl}
        {...props}
      />
      <CloseOutlined className="close-button" onClick={clearText} />
    </div>
  );
}