import { useParams } from "react-router";
import { ProfileContext, Role } from "contexts/ProfileContext"
import { useContext } from "react"
import { FaCopy } from "react-icons/fa"
import { isMinimumRole } from "contexts/ProfileUtil"
import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer";
import NoAccess from "components/errors/NoAccess"
import { PartialLoading } from "components/loading/Loading";
import { CopyButton } from "components/reusableButtons/CopyButton";
import { getApp } from "firebase/app";
import { useState, useEffect } from 'react';
import { doc, getFirestore, getDoc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { ManageSectionForm} from "./ManageSectionForm";
import { RemoveMemberButton } from "./RemoveMemberButton";
import { useCourseId } from "hooks/router/useUrlParams"

export const ManageSectionPage = () =>{
    const courseId = useCourseId()
    // admin only!!
    const { userData } = useContext(ProfileContext)
    const courseRole = userData?.courseRole
    if (!isMinimumRole(courseRole, Role.ADMIN)) {
        return <NoAccess />
    }

    // fetch section data 
    const sectionId = useParams().sectionId;

    const db = getFirestore(getApp());
    const sectionRef = doc(db, `sections/${sectionId}`);
    const [sectionData, sectionDataIsLoading, sectionDataError] = useDocumentData(sectionRef);
    
    // and SL data
    const [sectionLeaderId, setSectionLeaderId] = useState(null);
    const [slData, setSLData] = useState(null)
    const [slDataIsLoading, setSLDataIsLoading] = useState(true)
    const [slDataError, setSLDataError] = useState(false)

    // and members data
    const [memberIds, setMemberIds] = useState([]) // will be an array of strings
    const [membersData, setMembersData] = useState([]) // will be an array of dictionaries

    // update sl ID when section data is fetched
    useEffect(() => {
        if (sectionData && sectionData.sectionLeader && sectionData.members) {
            setSectionLeaderId(sectionData.sectionLeader);
            setMemberIds((_) => {return sectionData.members})
        }
    }, [sectionData]);

    // fetch sl data when sl ID is populated
    useEffect(() => {
        if(!sectionLeaderId){return}

        const getSLData = async () => {
            const slRef = doc(db, `/users/${sectionLeaderId}`);

            const response = await getDoc(slRef);
            if (response.exists()) {
                setSLData(response.data())
                setSLDataIsLoading(false)
            } 
            else {
                setSLDataError(true)
                setSLDataIsLoading(false)
            }
        }
        getSLData()

    }, [sectionLeaderId]);

    // fetch members data when list of member IDs are populated
    useEffect(() => {
        if(!memberIds.length){return}
        const makeMembersData = async () => {
            const fetchMembersDataPromises = sectionData.members.map((memberId) => {
                const memberRef = doc(db, `/users/${memberId}`);
                return getDoc(memberRef);
            });

            Promise.all(fetchMembersDataPromises)
            .then(membersDataArray => {
                setMembersData((_) => {return membersDataArray.map(
                    (response) => {
                        let memberData = response.data()
                        memberData['uid'] = response.id
                        memberData['uidCopy'] = <CopyButton toCopy={response.id} icon={<FaCopy/>}/>
                        memberData['removeMember'] = <RemoveMemberButton memberId={response.id} memberName={memberData.displayName} sectionId={sectionId} courseId={courseId}/>
                        return memberData
                    }
                )});
            })
            .catch(error => {
                console.log(error);
            });
            }
        
        makeMembersData()
    }, [memberIds])
    
    if (sectionDataError) {
        return <div>Error loading this section {sectionDataError.message}</div>;
    }

    if (slDataError) {
        return <div>Error fetching SL data.</div>;
    }

    if (sectionDataIsLoading || slDataIsLoading || !slData || !membersData) {
        return <PartialLoading />;
    }

    return <CoursePageBodyContainer
        mainColumn={
            <div className="mt-4" >
                <ManageSectionForm
                    sectionId={sectionId} 
                    courseId={courseId}
                    sectionLeaderId={sectionLeaderId} 
                    sectionData={sectionData} 
                    slData={slData}
                    membersData={membersData}
                />
            </div>
        }
        rightColumn={<></>}
        singleColumn={
            <div className="mt-4" >
                <ManageSectionForm 
                    sectionId={sectionId} 
                    courseId={courseId}
                    sectionLeaderId={sectionLeaderId} 
                    sectionData={sectionData} 
                    slData={slData}
                    membersData={membersData}
                />
            </div>
        }
    />
}