function isPrimitive(obj) {
  return obj !== Object(obj);
}

export function isEqual(first, second) {
  if (first === second) {
    return true;
  }

  if (
    isPrimitive(first) ||
    isPrimitive(second) ||
    typeof first === "function" ||
    typeof second === "function"
  ) {
    return first === second;
  }

  if (Object.keys(first).length !== Object.keys(second).length) {
    return false;
  }

  for (const key of Object.keys(first)) {
    if (!(key in second)) {
      return false;
    }

    if (!isEqual(first[key], second[key])) {
      return false;
    }
  }

  return true;
}
