import { useIsMobile } from "utils/general";
import { TeachNowTrainingSlide } from "./SlideTeachNowTraining";
import { TeachNowTrainingProvider } from "./TeachNowTrainingContext";
import { useContext } from "react";
import { ProfileContext } from "contexts/ProfileContext";
import { isMentor, isSectionLeader, isAdmin, isATypeOfStudent, isStaffRole } from "contexts/ProfileUtil";
import Gate from "contexts/Gate";
import { Navigate } from "react-router";
import { useCourseId } from "hooks/router/useUrlParams";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";



export const TeachNowTrainingOuter = () => {
    // If the screen is too small, don't render the component. We are requiring users to be on desktop for this
    const isMobile = useIsMobile();
    if(isMobile) return <div>Mobile not supported. For this task you need a bigger screen.</div>

    // get the current navigation location
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    // const armId = pathSegments[2] ? pathSegments[2] : 'teacherPuzzles'
    const slideId = pathSegments[3]; // can be undefined

    const courseId = useCourseId();

    const { userData } = useContext(ProfileContext)
    const isMentorRole = isMentor(userData.courseRole);
    const isSLRole = isSectionLeader(userData.courseRole)

    const isStaff = isStaffRole(userData.courseRole)

    const styleRetButton = {borderRadius: 5, padding: "10px", textDecoration: "none"}

    const showStudentTraining = isATypeOfStudent(userData.courseRole) || isStaff

    const armId = showStudentTraining ? 'studentTraining' : 'teachingTeamTraining'
    // const armId = isStaff ? 'studentTraining' : 'teachingTeamTraining'

    // if(!isMentorRole && !isStaff && !isSLRole) {
    //     return (
    //         // make this centered in the page 
    //         <div style={{textAlign: 'center', marginTop: '20px', fontSize: '20px'}}>
    //             <div>Sorry, you do not have access to this page.</div>
    //             <div className="d-flex justify-content-center">
    //                 <Link to={`/${courseId}`} className="text-purple text-center"  style={{ ...styleRetButton, color: "#b757f6"}}>
    //                 <FaHome size={50}/>
    //                 <div>Go to home page</div>
    //                 </Link>
    //             </div>
    //         </div>
    //     )
    // }


    return <TeachNowTrainingProvider
            startSlideId={slideId}
            userArm={armId}
            >
        <TeachNowTrainingSlide />
    </TeachNowTrainingProvider>
}

