import { CourseContext } from "contexts/CourseContext"
import { ProfileContext, Role } from "contexts/ProfileContext"
import { isMinimumRole } from "contexts/ProfileUtil"
import { TimeContext, useServerTime } from "contexts/TimeContext"
import { findAlternativeSection, hasActiveTeacher, joinSpecificZoom } from "course/makeupsection/makeupUtil"
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore"
import { functions } from "firebaseApp"
import { useCourseId } from "hooks/router/useUrlParams"
import { useContext, useState } from "react"
import { FaEnvelope, FaVideo, FaVideoSlash } from "react-icons/fa"
import Swal from "sweetalert2"

const OPEN_DELTA_MINS = -5
const CLOSE_DELTA_MINS = -20

export const JoinAlternativeSectionButton = ({
  nextSectionDate,
  sectionData
}) => {

  /**
   * This component is used to join a different section of the course.
   * Here is the logic:
   * ------------------
   * It should only be shown to students.
   * 
   * Case 1: We believe a teacher is coming (they rsvped or there is a cover)
   * - Let folks into their section
   * - Give 5 mins grace period. Then show the button.
   * - Both buttons will be visible
   * 
   * Case 2: We don't believe a teacher is coming
   * - Don't let folks into their section (keep button disabled)
   * - 5 mins before the section, switch the zoom button to the "join other section" button
   */


  const courseId = useCourseId()
  const { currCourseWeek } = useContext(CourseContext)

  const adjustedWeek = currCourseWeek - 1
  const serverTimeMs = useServerTime()
  

  const teacherInZoom = hasActiveTeacher(sectionData, adjustedWeek)
  const isEnabled = checkAltsOpen(nextSectionDate, serverTimeMs, teacherInZoom);

  // for now we only show this if you are a ta
  const {userData} = useContext(ProfileContext)
  const courseRole = userData.courseRole
  const isTeacher = isMinimumRole(courseRole, Role.MENTOR)
  const hasPermissions = isMinimumRole(courseRole, Role.TA)

  const onClick = async () => {
    const realSectionId = userData.courseSections[courseId]
    const timeIndex = sectionData.timeIndex
    const isMinor = userData.isMinor
    const alternativeSecitionId = await findAlternativeSection(
      realSectionId, 
      timeIndex, 
      adjustedWeek, // adjust for the 0 index
      courseId,
      isMinor
    )
    
    if(!alternativeSecitionId) {
      Swal.fire({
        title: 'No alternative section found',
        icon: 'error'
      })
      return
    }
    Swal.fire({
      title: 'Joining Zoom meeting...',
      html: alternativeSecitionId,
    })
    joinSpecificZoom(alternativeSecitionId, courseId, isTeacher, () => {})
  }


  if (!isEnabled || !hasPermissions) {
    return <></>
  }

  return <>
    <button
      onClick={onClick}
      className="btn btn-danger position-relative mt-2"
    >
      <FaVideo style={{ marginTop: -5 }} /> Join Another Section's Zoom
    </button>
  </>
}



function checkAltsOpen(nextSectionDate, serverNow, teacherInZoom) {
  if (!nextSectionDate) {
    console.log('No next section date')
    return false
  }
  if (teacherInZoom) {
    console.log('teacher in zoom')
    return false
  }
  const timeUntilMs = nextSectionDate.getTime() - serverNow;
  const timeUntilMins = timeUntilMs / 60000
  return timeUntilMins < OPEN_DELTA_MINS && timeUntilMins > CLOSE_DELTA_MINS
}

