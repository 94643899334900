import BootstrapTable from 'react-bootstrap-table-next';
import React, { Component, useEffect, useRef } from 'react';
import { useInfiniteHits } from 'react-instantsearch-hooks-web';
import PropTypes from 'prop-types';
import "./set-permissions.css"

export const InfiniteScrollTable = ({ columns, transformHitToRow, infiniteHitsProps, updateTrigger }) => {
    const {
        hits,
        isLastPage,
        showMore,
    } = useInfiniteHits(infiniteHitsProps);

    const data = hits.map(transformHitToRow)

    const sentinelRef = useRef<any>(null);
    
    // TODO: this is hacky; would be good to be able to use any generic `columns` but here am hardcoding for the columns of the set permissions page
    const sentinelElement = {
        'uidKey':'sentinel',
        'uidCopy': <div ref={sentinelRef} aria-hidden="true"></div>,
        'displayName':'' ,
        'email': '',
        'role': '',
        'id': 'sentinel'
    }

    useEffect(() => {
        if (sentinelRef.current !== null) {
          const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting && !isLastPage) {
                showMore();
              }
            });
          });
    
          observer.observe(sentinelRef.current);
    
          return () => {
            observer.disconnect();
          };
        }
      }, [isLastPage, showMore]);

    return <BootstrapTable 
      key={updateTrigger}
      data={[...data, sentinelElement]} 
      columns={columns} 
      bordered={false} 
      striped={true} 
      hover={true} 
      bootstrap4={true} 
      keyField='uidKey' 
      wrapperClasses="table-wrap"
      headerWrapperClasses="table-head"
    />
}