import React, { useContext, useEffect, useState } from 'react'
import { RoadmapContext } from 'course/contexts/RoadmapContext'
import BootstrapTable from 'react-bootstrap-table-next';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaLink } from 'react-icons/fa';
import { collectAssignments } from 'course/code/RoadmapAssignments';
import { useCourseId } from 'hooks/router/useUrlParams';
import { CoursePageBodyContainer } from 'components/layout/CoursePageBodyContainer';
import Gate from 'contexts/Gate';

const StickyHeaderTable = styled.div`
    .react-bootstrap-table {
        thead {
            position: sticky;
            top: 0;
        }
    }
`;

export const GradingSplash = () => {
    // linked to from admin home page if course is Foothill (TODO @miranda: this is hardcoded for now)
    const courseId = useCourseId()
    const { getAssignmentsInRoadmap, roadmapItemMap } = useContext(RoadmapContext)
    const [assignments, setAssignments] = useState([]) // [
    const [projectsList, metaData] = collectAssignments(assignments)
    const [data, setData] = useState([])

    useEffect(() => {
        const assns = getAssignmentsInRoadmap()
        setAssignments(assns)
    }, [roadmapItemMap])



    useEffect(() => {
        if (!metaData) return
        const newData = Object.entries(metaData).map(([key, value]) => value)
        setData(newData)
    }, [metaData])

    if (!metaData || !projectsList) return <></>

    return (
      <Gate when={Gate.hasAdminRole}>
        <CoursePageBodyContainer
            mainColumn={<GradingTable courseId={courseId} data={data}/>}
            rightColumn={<></>}
            singleColumn={<GradingTable courseId={courseId} data={data}/>}
        />
      </Gate>
    )
}

export const GradingTable = ({ courseId, data }) => {
    // table with all assignments and link to pages where you can download student submissions for grading.
    const columns = [
    {
        dataField: 'assnLink',
        text: 'Assignment'
    },
    {
        dataField: 'assnId',
        text: 'Student submissions',
        formatter: (cell, row) => {
            return <Link to={`/${courseId}/grading/${cell}`}><FaLink /></Link>
        }
    },
    {
        dataField: 'assnGroup',
        text: 'Unit',
        sort: true
    },
    {
        dataField: 'type',
        text: 'Type',
        sort: true
    },
    ]

    return (
        <StickyHeaderTable>
            <BootstrapTable 
                bordered={false} 
                striped={true} 
                hover={true} 
                bootstrap4={true} 
                keyField='assnId' 
                data={data} 
                columns={columns} 
            />
        </StickyHeaderTable>
    )
}