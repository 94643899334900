import { useState, useContext, useEffect} from "react"
import { useParams, useNavigate } from "react-router-dom"
import Swal from 'sweetalert2'
import { MeetingsContext } from "course/contexts/MeetingsContext"
import { CoursePageBodyContainer } from "../../../components/layout/CoursePageBodyContainer"
import Button from 'react-bootstrap/Button';
import { ProfileContext, Role, getCourseRoleNumber } from "../../../contexts/ProfileContext"
import Gate from "contexts/Gate"
import NoAccess from "components/errors/NoAccess"
import { formateDateWithSuffix, getTimeUntil } from "components/timezones/timezoneHelper";
import { isMinimumRole } from "contexts/ProfileUtil";
import { TipTap } from "components/richTextEditor/TipTap/TipTap";
import { SimpleButtonBar } from "components/richTextEditor/TipTap/buttonbars/SimpleButtonBar";
import { FaCloud, FaEdit, FaVideo } from "react-icons/fa";
import { EditableImage } from "components/editableResource/EditableImage";
import { Link } from "react-router-dom"


export const EventSplash = () => {
  const { userData } = useContext(ProfileContext)
  
  const isAdmin = Gate.hasAdminRole(userData)
  if (!isAdmin) {
    return <NoAccess />
  }

  return <CoursePageBodyContainer
    mainColumn={<EventEditorMain />}
    rightColumn={<></>}
    singleColumn={<EventEditorMain />}
  />
}

const EventEditorMain = () => {
  const { courseId, eventKey } = useParams();
  const { userData } = useContext(ProfileContext)
  const { meetingsMap, updateMeetingInDB } = useContext(MeetingsContext)
  const userRole = userData.courseRole

  if (eventKey === undefined || !(eventKey in meetingsMap)) {
    return <div
        className="card"
      >
        <div className="card-body">
          <h1>
            Event not found
          </h1>

      </div>
    </div>
  
  }

  const editEventSwal = async () => {
    // Show the Swal
    const eventEditForm = await Swal.fire({
      title: 'Edit Event Info',
      html:`
      <div style="display: flex; flex-direction: column; width: 100%;">
        <input type="text" id="title" class="swal2-input" placeholder="Enter new title">
        <input type="text" id="meetingLink" class="swal2-input" placeholder="Enter new meeting link">
        <input type="text" id="recordingLink" class="swal2-input" placeholder="Enter new recording link">
        <input type="number" id="duration" class="swal2-input" placeholder="Enter new meeting duration">
        <input type="date" id="date" class="swal2-input" placeholder="Enter new meeting date">
        <input type="time" id="time" class="swal2-input" placeholder="Enter new meeting time">
        <select id="minimumRole" name="Roles">
          <option value="">Select a minimum role</option>
          <option value="student">Student</option>
          <option value="sl">Section Leader</option>
          <option value="admin">Admin</option>
        </select>
      </div>
      `,
      focusConfirm: false,
      preConfirm: () => {
        try {
          const popup = Swal.getPopup()

          // create new datetime
          let startDate = popup.querySelector('#date').value;
          let startTime = popup.querySelector('#time').value;
          let start = ""
          if (startDate != "" || startTime != "") {
            if (startDate == "") { //chaging only time
              startDate = meetingsMap[eventKey].start.slice(0,10)
            } else if (startTime == "") { //changing only date
              startTime =  meetingsMap[eventKey].start.slice(11,-1)
            }
            const combinedString = `${startDate}T${startTime}`
            const date = new Date(combinedString)
            start = date.toISOString()
          }

          const title = popup.querySelector('#title').value;
          const zoomLink = popup.querySelector('#meetingLink').value;
          const recording = popup.querySelector('#recordingLink').value;
          const length = popup.querySelector('#duration').value;
          const minimumRole = popup.querySelector('#minimumRole').value;

          const eventInfo = {
            title, zoomLink, recording, length, minimumRole, start
          }

          const filteredObject = Object.entries(eventInfo).reduce( //filter only edited items
            (acc, [key, value]) => 
            (value != ""  ? { ...acc, [key]: value } : acc), {}
          );
          return filteredObject
      } catch(error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Something went wrong! Error: ${error}`,
        });
      }
      }
    });
    if (eventEditForm.isConfirmed && Object.keys(eventEditForm.value).length != 0) {
      updateMeetingInDB(eventKey, eventEditForm.value)
    }
  }

  return (
    <>
    <div className="splashCardMainTitle mt-4 d-flex align-items-center justify-content-between">Event Information</div>
    <EventCard event={meetingsMap[eventKey]} courseId = {courseId} eventKey = {eventKey} editable = {isMinimumRole(userRole, Role.ADMIN)}/> 
    <div className="container">
      <div className="d-flex justify-content-between">
        <Link to={`/${courseId}/eventseditor`} className="btn btn-primary mt-2">Back</Link>
        <Button onClick={editEventSwal} className="btn btn-primary mt-2 text-end"> Edit </Button>
      </div>
    </div>
    
    </>
  )  
}


export const EventCard = ({ event, courseId, eventKey, editable=false}) => {
    const {userData} = useContext(ProfileContext)
    const userRole = userData.courseRole
    const [editingDescription, setEditingDescription] = useState(false)
    const [zoomLink, setZoomLink] = useState(event?.zoomLink ? event.zoomLink : '')
    const [recording, setRecording] = useState(event?.recording ? event.recording : '')
    const [startDate, setStartDate] = useState(event?.start ? new Date(event.start) : new Date())
    const [title, setTitle] = useState(event?.title ? event.title : '');

    useEffect(()=>{
      setZoomLink(event?.zoomLink ? event.zoomLink : '')
      setRecording(event?.recording ? event.recording : '')
      setStartDate(event?.start ? new Date(event.start) : new Date())
      setTitle(event?.title ? event.title : '')
    }, 
    [event]
    )
    let timeUntil = getTimeUntil(startDate)
    if(startDate < new Date()){
      timeUntil = 'done'
    }
    const dateStr = formateDateWithSuffix(startDate)
  
    const formatter = new Intl.DateTimeFormat('en-US', { hour12: true, hour: 'numeric', minute: 'numeric' });
    let hourStr = formatter.format(startDate);
    hourStr = hourStr.replace(' AM', 'am').replace(' PM', 'pm')

    let lengthBase = event.length
    let lenthStr = event.length === 1 ? 'hour' : 'hours'
    if (event.length < 1) {
      lengthBase = event.length * 60
      lenthStr = 'mins'
    }
    const durationStr = `${lengthBase} ${lenthStr}`

    // some events are only for section leaders
    if(!isMinimumRole(userRole, getCourseRoleNumber(event.minimumRole))) {
      return <></>
    }
  
  
  
    // only admins can edit the event  
    return <div className="card">
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <EditableImage
          firebaseDocPath={`course/${courseId}/events/${eventKey}/docs/img`}
          imageStoragePath={`events/${courseId}/${eventKey}/img`}
          editable={editable}
          width='100%'
          height='200px'
        />
        {editable && <button 
          onClick={() => setEditingDescription(!editingDescription)}
          className="btn btn-light"
          style={{position:'absolute',marginTop:'-40px'}}><FaEdit /></button>}
        <div style={{height:10}}/>
        <TipTap 
          
          firebaseDocPath={`course/${courseId}/events/${eventKey}/docs/description`}
          editable={editingDescription}
          collaborative={editingDescription}
          buttonBar={SimpleButtonBar}
        />
        <div style={{height:5}}/>
        <b>{dateStr} {hourStr} ({timeUntil})</b><br />
        Length: {durationStr}<br />
        {zoomLink ?
          <a href={zoomLink} target="_blank" className="btn btn-primary mt-2"><FaVideo /> Zoom Link </a>
          : <button disabled={true} className="btn btn-primary mt-2"><FaVideo /> Zoom Link </button>
        }
        
        {recording && <a target="_blank" href={recording} className="btn btn-primary mt-2 ml-2"><FaCloud /> Recording </a>}
      </div>
    </div>
  }