
import { ProfileContext, Role } from "contexts/ProfileContext"
import { useContext, useEffect } from "react"
import { PartialLoading } from "components/loading/Loading"
import { Navigate, useNavigate } from "react-router"
import {useCourseId} from "hooks/router/useUrlParams"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { isMinimumRole } from "contexts/ProfileUtil"
import { ClassNotOpen } from "components/errors/ClassNotOpen"

export const SectionForumRedirect = ({}) => {
  const {userData} = useContext(ProfileContext)

  const navigate = useNavigate()
  const courseId = useCourseId()
  const courseRole = userData.courseRole

  // this is a dictionary of courseId: list of sectionRefs
  // I want to get the list of sectionRefs
  const sectionRefs = userData.sections[courseId]
  const nSections= sectionRefs ? sectionRefs.length : 0


  if(nSections > 1){
    return <>You are in multiple sections!</>
  }

  if(nSections === 1){
    const sectionRef = sectionRefs[0]
    const sectionId = sectionRef.id
    return <Navigate to={`/${courseId}/sectionforum/${sectionId}`} />
  }


  return <>You are not in a section yet!</>
}
