import { useStats } from 'react-instantsearch-hooks-web';
import "./set-permissions.css"

export const NumResultsWidget = () => {
    const { nbHits } = useStats();
    let numResultsString
    if (nbHits == 0){
        numResultsString = "No results :("
    } else if (nbHits == 1){
        numResultsString = "1 result"
    } else {
        numResultsString = `${nbHits} results`
    }
    return (
        <div className="num-results">
            {numResultsString}
        </div>
    );
}

