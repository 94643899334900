import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer"
import { Role } from "contexts/ProfileContext"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { TipTap } from "components/richTextEditor/TipTap/TipTap"
import { HandoutButtonBar } from "components/richTextEditor/TipTap/buttonbars/HandoutButtonBar"
import { FaEdit } from "react-icons/fa"
export const BlogPage = ({ }) => {
  
  return <CoursePageBodyContainer
    mainColumn={<HandoutInnder />}
    rightColumn={<></>}
    singleColumn={<HandoutInnder />}
  />
}

const HandoutInnder = () => {
  const blogId = useParams().blogId

  return <div style={{marginTop:20}}><BlogRichTextEditor
    firebaseDocPath={`blog/${blogId}`}
    minRole={Role.UNREGISTERED}
  /></div>
}

const BlogRichTextEditor = ({ firebaseDocPath, minRole = Role.TA }) => {

  const hasPermissions = true;
  const [isEditing, setIsEditing] = useState(false)
  return <div className="handout-tiptap">
  <TipTap
    firebaseDocPath={firebaseDocPath}
    editable={isEditing}
    collaborative={isEditing}
    buttonBar={HandoutButtonBar}
    showLoadingSkeleton={true}
  />
    {hasPermissions && <button className="btn btn-light" onClick={() => setIsEditing(!isEditing)}>
      {isEditing ? "Done" : <FaEdit/>}
    </button>}
  </div>
}