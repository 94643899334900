import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Role } from "../../contexts/ProfileContext";
import { roleToString, roleToFirebaseString } from "../../contexts/ProfileUtil";
import Papa from "papaparse";
import * as yup from 'yup';
import Swal from "sweetalert2"
import { getFunctions, httpsCallable } from "firebase/functions";
// @ts-ignore
import { useCourseId } from "hooks/router/useUrlParams";
import { RoleSelect } from "./RoleSelect"
import { IsMinorSelect } from "./IsMinorSelect"

type ParsedUser = {
    displayName: string,
    email: string
}

type AddUserPermsParams = {
    parsedData: ParsedUser[],
    courseId: string,
    newRole: string,
    isMinor: boolean
}

type AddUserPermsError = {
    uid?: string
    email: string
    displayName: string
    reason: string
}

type ChangePermissionsResult = {
    success: boolean
    reason?: string
}

type AddUserPermsResult = ChangePermissionsResult & {
    errors?: AddUserPermsError[]
}

export const AddUsersForm = ({ resetAndRefresh }) => {
    const [emailsCSVString, setEmailsCSVString] = useState<string>('')
    const [role, setRole] = useState<string>('')
    const [formSubmitLoading, setFormSubmitLoading] = useState<boolean>(false)
    const [isMinor, setIsMinor] = useState<string>('') // note that isMinor will only ever be "true" or "false"; using string so that we can have a default emptystring option (so as to force users to select isMinor manually instead of having a default option)
    const functions = getFunctions();
    const courseId = useCourseId()

    const addUserPermsFirebase = httpsCallable<AddUserPermsParams, AddUserPermsResult>(functions, "addUserPerms");

    const addUsers = (emailsCSVString: string, role: string, isMinor: boolean) => {
        setFormSubmitLoading(true)

        let parsedData
        try {
            parsedData = (Papa.parse(emailsCSVString, {
                header: false,
                skipEmptyLines: true
              }).data as string[][])
              .map(([displayName, email]) => ({
                displayName: displayName.trim(),
                email: email.trim(),
            }));
        }
        catch (err) {
            Swal.fire({
            title: 'Input is misformatted',
            html: "Input must have one name and email per line separated by a comma, like this:<br/>name1,email1@email.com<br/>name2,email2@email.com",
            icon: 'error',
            })
            setFormSubmitLoading(false)
            return
        }
        
        const schema = yup.array().of(
        yup.object().shape({
            displayName: yup
            .string()
            .min(2, 'Display Name must be at least 2 characters long')
            .required('Display Name is required'),
            email: yup
            .string()
            .email('Email must be a valid email')
            .required('Email is required'),
        }),
        );

        schema
        .validate(parsedData)
        .then(async function (valid) {
            const res = await addUserPermsFirebase({parsedData, courseId, newRole: roleToFirebaseString(parseInt(role)), isMinor})
            if (res.data.success){
                Swal.fire({
                    title: 'Success!',
                    text: 'Users added successfully.',
                    icon: 'success',
                })
                setFormSubmitLoading(false)
                resetAndRefresh()
                return
            } else {
                const errorText = res.data.reason? res.data.reason : getErrorsHTML(res.data.errors!)
                Swal.fire({
                    title: 'Something went wrong!',
                    html: 'Users could not be added. ' + errorText,
                    icon: 'error',
                })
                setFormSubmitLoading(false)
                return
            }
        })
        .catch(function (err) {
            Swal.fire({
                title: 'Input is misformatted',
                text: err,
                icon: 'error',
            })
            setFormSubmitLoading(false)
            return
        });
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        addUsers(emailsCSVString, role, JSON.parse(isMinor))
    }

    const explanationText='Add users to the course by entering their names and email addresses in CSV format (or pasting from a spreadsheet), one user per line, and selecting the appropriate role and age group. Emails which are already registered with roles will have their roles and isMinor fields overwritten, but not their display names!'

    const placeholderText="name1,email1@email.com\nname2,email2@email.com\n..."

    return (
    <Form onSubmit={handleSubmit}>
        <Row style={{paddingBottom:"1rem"}}>
            <Form.Text>{explanationText}<br/><strong>Please ensure that any special characters (e.g. ñ, í, ş, etc.) show up properly in the input field below before you submit the form.</strong></Form.Text>
        </Row>
        <Row>
        <Col>
            <Form.Control 
            as="textarea"
            rows={5}
            placeholder={placeholderText}
            value={emailsCSVString}
            onChange={(e) => setEmailsCSVString(e.target.value)}
            />
        </Col>
        <Col style={{maxWidth:"30%"}}>
        <RoleSelect role={role} setRole={setRole}/>
        <IsMinorSelect setIsMinor={setIsMinor} plural={true}/>
        <Button 
            variant='success' 
            type='submit' 
            disabled={emailsCSVString === '' || role === null || isMinor === '' || formSubmitLoading } 
            style={{marginTop:"1rem"}}>
                {formSubmitLoading ? 'Loading...' :'Set permissions'}
        </Button>
        </Col>
        </Row>
    </Form>
    );
}

const getErrorsHTML = (errors: AddUserPermsError[]) => {
    return errors.map((error) => {
        if (error.uid){
            return `User ${error.uid} (${error.displayName}) could not be added. ${error.reason}`
        } else {
            return `User ${error.email} (${error.displayName}) could not be added. ${error.reason}`
        }
    }).join("</br>")
}
