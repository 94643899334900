import { Select } from "antd";
import { useConfigure } from 'react-instantsearch-hooks-web';

export const RoleAgeFilter = (props) => {
    const allRoles = ['1','2','3','4','5']

    const { refine } = useConfigure({
        filters: `role:[${allRoles}]`,
        ...props
    })

    const options = [
        { label: "Student", value: '1' },
        { label: "SL", value: '2' },
        { label: "TA", value: '3' },
        { label: "Admin", value: '4' },
        { label: "Instructor", value: '5' },
        { label: "Minor (<18)", value: 'true' },
        { label: "Not Minor (18+)", value: 'false' },
    ];

    const updateFilters = (allFilters: string[]) => {
        const isMinor = allFilters.filter((filter) => filter == 'true' || filter == 'false')
        const roles = allFilters.filter((filter) => !isMinor.includes(filter))

        const rolesQueryString = roles.length == 0 ? `role:[${allRoles}]` : `role:[${roles}]`

        if (isMinor.length > 0){
            // the facetFilters query must be a nested array so that if isMinor:true and isMinor:false are both selected, we show hits with EITHER value
            // docs: https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/
            const isMinorFacetQuery = [isMinor.map((filter) => `isMinor:${filter}`)]
            refine({filters: `${rolesQueryString}`,
                    facetFilters: isMinorFacetQuery})
        } else {
            refine({filters: `${rolesQueryString}`})
        }
    }

    const clearFilters = () => {
        refine({filters: `role:[${allRoles}]`})
    }

    return (
        <Select
        allowClear
        mode="multiple"
        style={{ width: "100%" }}
        placeholder="Filter by role or age"
        onChange={updateFilters}
        onClear={clearFilters}
        options={options}
        />
    );
}