import {
  Highlight,
  InstantSearch,
  useInstantSearch
} from "react-instantsearch-hooks-web";
import React, { useState, useContext } from "react"
import { ProfileContext, Role } from "../../contexts/ProfileContext"
import { CourseContext } from "../../contexts/CourseContext"
import { isMinimumRole } from "../../contexts/ProfileUtil"
import { NoAccess } from "../../components/errors/NoAccess"
import { CopyButton } from "../../components/reusableButtons/CopyButton"
import { CoursePageBodyContainer } from "../../components/layout/CoursePageBodyContainer"
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { roleToString } from "../../contexts/ProfileUtil";
import "./set-permissions.css"
// @ts-ignore
import { typesenseServerConfig } from 'config';
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { InfiniteScrollTable } from "./InfiniteScrollTable";
import { RoleAgeFilter } from "./RoleAgeFilter";
import { UserTableSearchBar } from "./UserTableSearchBar";
import { AddUsersForm } from "./AddUsersForm"
import { NumResultsWidget } from "./NumResultsWidget";
import { EditRowButton } from "./EditRowButton";
import { isEqual } from "./isEqual"

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: typesenseServerConfig, 
    additionalSearchParameters: {
        queryBy: "displayName,email,uid",
        sortBy: "role:desc"
    },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

const cache = getInMemoryCache();

export const SetPermissionsPage = () => {
  const { userData } = useContext(ProfileContext)
  const { rosterTypesenseIndex } = useContext(CourseContext)
  const courseRole = userData?.courseRole
  // admin only!!
  if (!isMinimumRole(courseRole, Role.ADMIN)) {
    return <NoAccess />
  }

  if (!rosterTypesenseIndex) {
    return <div>Course roster index not found! Make sure Typesense is set up properly for this course.</div>
  }

  return (
    <InstantSearch
        indexName={rosterTypesenseIndex}
        searchClient={searchClient}
    >
    <CoursePageBodyContainer
      mainColumn={<SetPermissions/>}
      rightColumn={<></>}
      singleColumn={<SetPermissions/>}
    />
    </InstantSearch>
  );
}

const SetPermissions = () => {
    // NOTE: if columns are made sortable, infinite scroll will break -- see InfiniteScrollTable.tsx
    const { refresh, setUiState } = useInstantSearch();
    const [updateTrigger, setUpdateTrigger] = useState(0)

    function resetAndRefresh() {
      setUiState((uiState) => ({
        ...uiState,
        instant_search: {
          ...uiState.instant_search,
          page: 0
        }
      }));
      cache.invalidate();
      refresh();
      setUpdateTrigger(updateTrigger + 1)
    }

    const columns = [
      {dataField: 'uidKey', text:'key', hidden:true},
      {dataField: 'uidCopy', text: 'UID', sort:false, editable:false},
      {dataField: 'displayName', text: 'Display Name', sort:false, editable:false},
      {dataField: 'email', text: 'Email', sort:false, editable:false}, 
      {dataField: 'role', text: 'Role', sort:false, editable:false}, 
      {dataField: 'isMinor', text: 'Minor? (<18)', sort:false, editable:false},
      {dataField: 'editButton', text: 'Edit', sort:false, editable:false}
    ]

    const transformHitToRow = (hit) => {
      let isMinorText = "unknown"
      if ('isMinor' in hit && hit.isMinor){
        isMinorText = "yes"
      } else if ('isMinor' in hit && !hit.isMinor){
        isMinorText = "no"
      }
      return {
        uidKey: hit.uid,
        uidCopy: <CopyButton toCopy={hit.uid}/>,
        displayName: <Highlight attribute="displayName" hit={hit}>{hit.displayName}</Highlight>,
        email: <Highlight attribute="email" hit={hit}>{hit.email}</Highlight>,
        role: roleToString(hit.role),
        isMinor: isMinorText,
        editButton: <EditRowButton 
          uid={hit.uid} 
          displayName={hit.displayName} 
          email={hit.email} 
          role={hit.role} 
          isMinor={'isMinor' in hit ? hit.isMinor : false}
          resetAndRefresh={resetAndRefresh}
        />
      }
    }

    return <>
    <h1>Invite users & manage permissions</h1>
    <Row style={{paddingBottom:"2rem"}}>
      <AddUsersForm 
          resetAndRefresh={resetAndRefresh}
      />
    </Row>
    <Row>
      <Col> <UserTableSearchBar/> </Col>
      <Col style={{maxWidth:"30%"}}> <RoleAgeFilter/> </Col>
    </Row>
    <Row><NumResultsWidget/></Row>
    <Row><Form.Text>Click any cell in the table to edit the information; press enter to save your changes.</Form.Text></Row>
    <InfiniteScrollTable 
      columns={columns} 
      transformHitToRow={transformHitToRow} 
      infiniteHitsProps={{cache: cache}} 
      updateTrigger={updateTrigger}
    />
    <br/>
    </>
}

function getStateWithoutPage(state) {
  const { page, ...rest } = state || {};
  return rest;
}

function getInMemoryCache() {
  let cachedHits = null;
  let cachedState = null;
  return {
    read({ state }) {
      return isEqual(cachedState, getStateWithoutPage(state))
        ? cachedHits
        : null;
    },
    write({ state, hits }) {
      cachedState = getStateWithoutPage(state);
      cachedHits = hits;
    },
    invalidate() {
      cachedHits = null;
      cachedState = null;
    }
  };
}
