import Form from 'react-bootstrap/Form';
import { Role } from "../../contexts/ProfileContext";
import { roleToString, roleToFirebaseString } from "../../contexts/ProfileUtil";

export const RoleSelect = ({role, setRole, includeDefault=true}) => {
  const onRoleChange = (e) => {
    setRole(e.target.value)
  }
  
  return (
    <Form.Select
      aria-label="Role select menu"
      value={role}
      onChange={onRoleChange}
    >
      {includeDefault ? <option>Select role</option> : <></>}
      {Object.keys(Role).map((role) => {
        if (isNaN(Number(role))) {
          return <option key={role} value={Role[role]}>{roleToString(Role[role])}</option>
        }
      })
      }
    </Form.Select>
  )
}