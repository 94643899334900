import Form from 'react-bootstrap/Form';
import { useState } from 'react';

export const IsMinorSelect = ({setIsMinor, plural=false, defaultVal=''}) => {
  const [nonMinorChecked, setNonMinorChecked] = useState(defaultVal === 'false');
  const [minorChecked, setMinorChecked] = useState(defaultVal === 'true');

  const handleNonMinorChange = e => {
    if (e.target.checked) {
      setNonMinorChecked(true);
      setMinorChecked(false);
      setIsMinor('false');
    }
  };

  const handleMinorChange = e => {
    if (e.target.checked) {
      setMinorChecked(true);
      setNonMinorChecked(false);
      setIsMinor('true');
    }
  };

  return (
    <Form>
      <Form.Check
        type="radio"
        label={plural ? "Non-minors (18 or older)" : "Non-minor (18 or older)"}
        name="group1"
        id={`radio-1`}
        onChange={handleNonMinorChange} 
        checked={nonMinorChecked}
      />
      <Form.Check
        type="radio"
        label={plural ? "Minors (younger than 18 years old)" : "Minor (younger than 18 years old)"}
        name="group1"
        id={`radio-2`}
        onChange={handleMinorChange} 
        checked={minorChecked}
      />
    </Form>
  );
}
