import { doc } from "firebase/firestore";
import { getCollectionRef } from "../refs";
import { pickBy } from "lodash";

import type {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";

import type { CountryCode } from "../../data/countries/country_code_to_names";
import type { CourseId } from "../../types";
import type { Section } from "./Section";
import { SmallGroup } from "./SmallGroup";

export type UserRole = "sl" | "student" | "admin" | "ta" | "instructor" | "mentor" | "experiencedstudent";

// TODO: complete the User model schema
export type User = {
  id: string;
  roles: Record<CourseId, UserRole>;
  sections: Record<CourseId, Array<DocumentReference<Section>>>;
  smallGroups: Record<CourseId, Array<DocumentReference<SmallGroup>>>;
  photoURL: string;
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  isMinor: boolean;
  city: string;
  shareChatGPTConvos: boolean;
  shareCode: boolean;
  sharePeerLearn: boolean;
  country: CountryCode | "";
  portfolio: Array<string>;
  confirmedStudent: Record<CourseId, Array<Boolean>>;
  confirmedSectionLeader: Record<CourseId, Array<Boolean>>;
  okToEmail: boolean;
  sentFirstEmail: boolean;  
  aboutMe: string;
  goals: string;
  wantToConnect: boolean;
  okToSendNotifications: boolean;
  finalProjectCommunities: Record<CourseId, Array<string>>;
};

const USER_CONVERTER: FirestoreDataConverter<User> = {
  toFirestore: (user: User): DocumentData => {
    return pickBy({
      // NOTE: `roles` and `sections` are currently omitted, we don't have any
      // instances where we would want them to be updated from the frontend
      // for now, so this is fine(tm)
      photoURL: user.photoURL,
      displayName: user.displayName,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      city: user.city,
      isMinor: user.isMinor,
      shareChatGPTConvos: user.shareChatGPTConvos,
      shareCode: user.shareCode,
      sharePeerLearn: user.sharePeerLearn,
      confirmedStudent: user.confirmedStudent,
      confirmedSectionLeader: user.confirmedSectionLeader,
      // this will overwrite the user's country (whatever format it is
      // in the firestore document) with a string instead of an object. see
      // fromFirestore below for a brief explanation
      country: user.country,
    });
  },

  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options: SnapshotOptions
  ): User {
    const data = snapshot.data(options);

    // `country` has historically been stored as an object on Firestore
    // (of type {country: string, eng_name: string, native_name: string})
    //
    // instead of storing derived data, we should transition to storing
    // only the country code and computing the derived data dynamically
    // so that it doesn't get out of sync if our data source changes. this
    // handles converting the multiple data types into a consistent interface
    let country: CountryCode | "";
    switch (typeof data.country) {
      case "string":
        country = data.country as CountryCode;
        break;
      case "undefined":
        country = "";
        break;
      default:
        country = data.country.code || "";
        break;
    }

    const okToEmail = data.hasOwnProperty("okToEmail") ? data.okToEmail : true;
    const okToSendNotifications = data.hasOwnProperty("okToSendNotifications") ? data.okToSendNotifications : true;

    return {
      id: snapshot.id,
      roles: data.roles,
      sections: data.sections,
      smallGroups: data.smallGroups,
      photoURL: data.photoURL,
      displayName: data.displayName,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      city: data.city,
      portfolio: data.portfolio,
      isMinor: data.isMinor,
      shareChatGPTConvos: data.shareChatGPTConvos,
      shareCode: data.shareCode,
      sharePeerLearn: data.sharePeerLearn,
      country,
      confirmedStudent: data.confirmedStudent,
      confirmedSectionLeader: data.confirmedSectionLeader,
      okToEmail,
      okToSendNotifications,
      sentFirstEmail: data.sentFirstEmail,
      aboutMe: data.aboutMe,
      goals: data.goals,
      wantToConnect: data.wantToConnect,
      finalProjectCommunities: data.finalProjectCommunities
    };
  },
};

export const getUserCollectionRef = () =>
  getCollectionRef("users", USER_CONVERTER);
export const getUserDocumentRef = (id: string) =>
  doc(getUserCollectionRef(), id);

  export const getUserRoleRef = (userId: string, courseId: CourseId) => {
    return doc(getUserDocumentRef(userId), "roles", courseId);
  }