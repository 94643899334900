import { createContext, useContext, useEffect, useState } from 'react';
import { doc, getDoc, getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { useParams } from 'react-router';
import { TimeContext } from './TimeContext';



const defaultData = {
  courseId: '',
  courseName: '',
  courseDescription: '',
  courseTimezone: '',
  courseStartDate: '',
  courseEndDate: '',
  nCourseWeeks: 0,
  firstSectionTimestamp: '',
  lastSectionTimestamp: '',
  courseType: '',
  currCourseWeek: 0,  // resets on monday
  currSectionWeek: 0, // resets on friday
  courseFeatures: [],
  courseVideoId: '',
  sectionTimeDelta: 0,
  slFeatures: [],
  isCourseAsynchronous: false,
  isFeatureEnabled: (featureName: string, isSl?: boolean) => false,
  getSectionsSnapshot: () => { return null },
  getFirstDayOfSection: (timezoneString: string, sectionIndex: number) => { return null },
  getNextSectionIndex: (timezoneString: string, sectionIndex: number) => { return 0 },
  getNextSectionDate: (timezoneString: string, sectionIndex: number) => { return null },
  rosterTypesenseIndex: '',
  storiesTypesenseIndex: '',
  roadmapList: [],
  courseCanvasAuthLink: "",
  isCodeInPlaceCourse: false,
  usesKarel: false,
  usesTeachNow: false
};


/**
 * TODO: there are two big refactors (one easy, one hard)
 * 
 * 1. in some places in the database, weeks are 0 indexed, in others they are 1 indexed. 
 *    This should be consistent (likely 0 indexed). When displaying to the user, we can add 1
 * 
 * 2. This context uses a long list of useStates (each with their one setState). There is a 
 *    great chance to reduce the amount of code. 
 */

export const CourseContext = createContext(defaultData);

interface CourseContextType {
  courseId: string;
  courseName: string;
  courseDescription: string;
  courseTimezone: string;
  courseStartDate: string;
  courseEndDate: string;
  nCourseWeeks: number;
  currSectionWeek: number;
  firstSectionTimestamp: string;
  lastSectionTimestamp: string;
  courseType: string,
  sectionTimeDelta: number,
  courseVideoId: string,
  isCourseAsynchronous: boolean,
  isFeatureEnabled: (featureName: string, isSl?: boolean) => boolean,
  getSectionsSnapshot: () => any,
  getFirstDayOfSection: (timezoneString: string, sectionIndex: number) => Date,
  getNextSectionIndex: (timezoneString: string, sectionIndex: number) => number,
  getNextSectionDate: (timezoneString: string, sectionIndex: number) => Date,
  courseFeatures: string[],
  slFeatures: string[],
  rosterTypesenseIndex: string,
  storiesTypesenseIndex: string,
  roadmapList: string[],
  courseCanvasAuthLink: string,
  isCodeInPlaceCourse: boolean,
  usesKarel: boolean,
  usesTeachNow: boolean
}

interface CourseContextProviderProps {
  children: React.ReactNode;
}






export const CourseProvider: React.FC<CourseContextProviderProps> = ({ children }) => {
  const { courseId } = useParams()
  const {getServerTimeMs} = useContext(TimeContext)

  // TODO: this should be cleaned up into a single useDocumentDataOnce call!!

  const [courseName, setCourseName] = useState<string>('');
  const [courseDescription, setCourseDescription] = useState<string>('');
  const [courseTimezone, setCourseTimezone] = useState<string>('');
  const [courseStartDate, setCourseStartDate] = useState<string>('');
  const [courseEndDate, setCourseEndDate] = useState<string>('');
  const [nCourseWeeks, setnCourseWeeks] = useState<number>(0);
  const [firstSectionTimestamp, setFirstSectionTimeStamp] = useState<string>('');
  const [courseType, setCourseType] = useState<string>('');
  const [courseCanvasAuthLink, setCanvasCourseAuthLink] = useState<string>('');

  const [isCourseAsynchronous, setIsCourseAsynchronous] = useState<boolean>(false);
  const [courseFeatures, setCourseFeatures] = useState<string[]>([])
  const [slFeatures, setSlFeatures] = useState<string[]>([])
  const [courseVideoId, setCourseVideoId] = useState<string>('');
  const [sectionTimeDelta, setSectionTimeDelta] = useState<number>(0);
  const [rosterTypesenseIndex, setRosterTypesenseIndex] = useState<string>('');
  const [storiesTypesenseIndex, setStoriesTypesenseIndex] = useState<string>('');
  const [roadmapList, setRoadmapList] = useState<string[]>(["student", "sl"])
  const [isCodeInPlaceCourse, setIsCodeInPlaceCourse] = useState<boolean>(false)
  const [usesKarel, setUsesKarel] = useState<boolean>(false)
  const [usesTeachNow, setUsesTeachNow] = useState<boolean>(false)



  // make sure its a real course

  const db = getFirestore()
  const courseDocRef = doc(db, `course/${courseId}`);

  const getFirstDayOfSection = (timezoneString, sectionIndex) => {
    // NOTE: when calling this function, make sure to check if firstSectionTimestamp is loaded or use it in a useEffect
    if (!firstSectionTimestamp) {
      throw new Error('Timestamp not loaded yet');
    }
    const firstSectionDate = new Date(firstSectionTimestamp);
    firstSectionDate.setHours(firstSectionDate.getHours() + sectionIndex)
    return firstSectionDate;
  }

  function getNextSectionIndex(timezoneString, sectionIndex) {
    // NOTE: when calling this function, make sure to check if firstSectionTimestamp is loaded or use it in a useEffect
    const firstSection = getFirstDayOfSection(timezoneString, sectionIndex);
    let currSection = firstSection;
    const oneHourInMilliseconds = 60 * 60 * 1000;
    let weekIndex = 0
    while (new Date() >= new Date(currSection.getTime() + oneHourInMilliseconds)) {
      currSection = new Date(currSection.getTime() + 7 * 24 * 60 * 60 * 1000);
      weekIndex += 1
    }
    return weekIndex;
  }

  function getNextSectionDate(timezoneString, sectionIndex) {
    // NOTE: when calling this function, make sure to check if firstSectionTimestamp is loaded or use it in a useEffect
    const firstSection = getFirstDayOfSection(timezoneString, sectionIndex);
    let currSection = firstSection;
    const oneHourInMilliseconds = 60 * 60 * 1000;
    while (new Date() >= new Date(currSection.getTime() + oneHourInMilliseconds)) {
      currSection = new Date(currSection.getTime() + 7 * 24 * 60 * 60 * 1000);
    }
    return currSection;
  }

  useEffect(() => {
    const getCourseData = async () => {
      if (!courseId) return;
      const courseDoc = await getDoc(courseDocRef);
      if (courseDoc.exists()) {
        const courseData = courseDoc.data();
        setCourseName(courseData.name ?? "");
        setCourseDescription(courseData.description ?? "");
        setCourseTimezone(courseData.timezone ?? "");
        setCourseStartDate(courseData.startDate ?? "");
        setnCourseWeeks(courseData.nWeeks ?? 0);
        setFirstSectionTimeStamp(courseData.firstSectionTimestamp ? `${courseData.firstSectionTimestamp}-07:00` : "");
        setCourseEndDate(courseData.endDate ?? "");
        setCourseType(courseData.type ?? "");
        setCourseVideoId(courseData.videoId ?? "");
        setCourseFeatures(courseData.features ?? []);
        setSlFeatures(courseData.slFeatures ?? []);
        setSectionTimeDelta(courseData.sectionTimeDelta ?? 0); // the number of hours of section a week
        setRosterTypesenseIndex(courseData.rosterTypesenseIndex ?? '');
        setStoriesTypesenseIndex(courseData.storiesTypesenseIndex ?? '');
        setCanvasCourseAuthLink(courseData.canvasAuthLink ?? "")
        const verboseRoadmapList = ["student", "sl", ...courseData.roadmapList ?? ["student", "sl"]]
        const uniqueRoadmap = array => array.filter((v, i) => array.indexOf(v) === i)
        setRoadmapList(uniqueRoadmap(verboseRoadmapList))
        setIsCodeInPlaceCourse("isCodeInPlaceCourse" in courseData ? courseData.isCodeInPlaceCourse : true)
        setUsesKarel("usesKarel" in courseData ? courseData.usesKarel : true)
        setUsesTeachNow("usesTeachNow" in courseData ? courseData.usesTeachNow : true)


        setIsCourseAsynchronous(courseData.type ? courseData.type === "asynchronous" : false)
      }
    }
    getCourseData();
  }, [courseId])


  const isFeatureEnabled = (featureName: string, isSl: boolean = false) => {
    const features = isSl ? slFeatures : courseFeatures
    return features.includes(featureName)
  }

  const getSectionsSnapshot = async () => {
    // fetches sections for this course
    const sectionRef = collection(db, "sections")
    const q = query(sectionRef, where("courseId", "==", courseId));
    const querySnapshot = await getDocs(q);
    return querySnapshot
  }

  // These are "one indexed" in that the first week of class is 1
  const currSectionWeek = calcCurrWeek(firstSectionTimestamp, getServerTimeMs())
  const currCourseWeek = calcCurrWeek(courseStartDate, getServerTimeMs())

  // lastSectionTimestamp is an ISO string
  const lastSectionTimestamp = calcLastSectionTimestamp(firstSectionTimestamp, sectionTimeDelta)


  return (
    <CourseContext.Provider value={{
      courseId,
      courseName,
      courseDescription,
      courseTimezone,
      courseStartDate,
      courseEndDate,
      nCourseWeeks,
      firstSectionTimestamp,
      lastSectionTimestamp,
      courseType,
      courseFeatures,
      slFeatures,
      courseVideoId,
      currSectionWeek,
      currCourseWeek,
      isCourseAsynchronous,
      isFeatureEnabled,
      getSectionsSnapshot,
      sectionTimeDelta,
      getFirstDayOfSection,
      getNextSectionDate,
      getNextSectionIndex,
      rosterTypesenseIndex,
      storiesTypesenseIndex,
      roadmapList,
      courseCanvasAuthLink,
      isCodeInPlaceCourse,
      usesKarel,
      usesTeachNow
    }}>
      {children}
    </CourseContext.Provider>
  );

}


/**
 * If you are before the start timestamp, it will return 0
 * If you are right after the timestamp, it will return 1
 * Therfore, consider it one indexed as the first week of sections will be 1
 * @param startTimestamp 
 * @param serverTimestamp 
 * @returns 
 */
export function calcCurrWeek(startTimestamp, serverTimestamp) {
  // calculate the current week
  const startDate = new Date(startTimestamp);
  const serverDate = new Date(serverTimestamp);
  const oneHourInMilliseconds = 60 * 60 * 1000;
  let currDate = startDate;
  let weekIndex = 0
  while (serverDate >= new Date(currDate.getTime() + oneHourInMilliseconds)) {
    // add 7 days to the current section
    currDate = new Date(currDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    weekIndex += 1
  }
  return weekIndex;
}

const calcLastSectionTimestamp = (firstSectionTimestamp, sectionTimeDelta) => {
  if (!firstSectionTimestamp) {
    return (new Date().toISOString());
  }

  const startDate = new Date(firstSectionTimestamp)
  const deltaHours = sectionTimeDelta * 60 * 60 * 1000
  const lastSectionTimestamp = new Date(startDate.getTime() + deltaHours)
  return lastSectionTimestamp.toISOString() 

}