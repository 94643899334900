import { getApp } from "firebase/app"
import { collection, doc, getFirestore, query, updateDoc, where } from "firebase/firestore"
import { useCourseId } from "hooks/router/useUrlParams"
import BootstrapTable from 'react-bootstrap-table-next';
import { FaVideo, FaBolt, FaCopy, FaClipboard, FaCheck } from "react-icons/fa"
import { Link } from "react-router-dom";
import Swal from "sweetalert2"
import { ProfileContext, Role } from "contexts/ProfileContext"
import { CourseContext } from "contexts/CourseContext"
import { useContext } from "react"
import { CopyButton } from "../../components/reusableButtons/CopyButton"
import { SectionButton } from "../../components/reusableButtons/SectionButton"
import {
  getLocalUTCTimezone,
  getShortSectionTimeStr,
} from "components/timezones/timezoneHelper";
import { isMinimumRole } from "contexts/ProfileUtil"
import NoAccess from "components/errors/NoAccess"
import { useUserId } from "hooks/user/useUserId"
import { CoursePageSingleCol } from "components/layout/CoursePageSingleCol"
import { useCollection } from "react-firebase-hooks/firestore"
import { useServerTime } from "contexts/TimeContext"
import { hasActiveTeacher } from "course/makeupsection/makeupUtil"
import { CoverButton } from "./CoverSectionButton"


export function needsCoverCheck(section, sectionNumber, firstSectionTimeStampOfWeek, now, lastSectionTimestamp) {
  // 
  // const covers = section?.covers
  // if (covers){
  //   // don't need a cover if there already is one
  //   if(covers[sectionNumber]?.name) return false
  // }

  if(now > lastSectionTimestamp.getTime()) {
    return false}
  // don't need a cover if there is an RSVP from the SL
  const slRSVP = section?.sectionLeaderRSVP
  const hasRSVP = slRSVP && (sectionNumber in slRSVP)

  // how long until the section?
  const timeIndex = section?.timeIndex
  if(timeIndex==="undefined") return false
  const nextSectionDate = new Date(firstSectionTimeStampOfWeek.getTime() + (timeIndex) * 60 * 60 * 1000)

  // this is not a string
  const timeUntilMs = nextSectionDate.getTime() - now
  const timeUntilMins = timeUntilMs / 1000 / 60
  console.log(timeUntilMins, timeIndex)



  // if the RSVP is false, we need a cover

  if (hasRSVP) {
    // if there is ever a false RSVP, we need a cover 
    if (!slRSVP[sectionNumber]) {
      return true
    }
  } else {
    // if there is no RSVP, we need a cover if the section is less than 2 hours away
    if (timeUntilMins < 180) {
      return true
    }
  }
  

  // if we are 5 mins passed time, and the section leader has not shown up, we need a cover
  if (timeUntilMins < -5) {
    // did the SL show up?
  }



  // otherwise, we dont need a cover
  return false
}

export const MonitorSections = () => {


  const courseId = useCourseId()
  const db = getFirestore(getApp())
  const { userData } = useContext(ProfileContext)
  const {  firstSectionTimestamp, lastSectionTimestamp } = useContext(CourseContext)
  const courseRole = userData?.courseRole


  const sectionRef = collection(db, "sections")
  const sectionQuery = query(sectionRef, where("courseId", "==", courseId));
  const [sectionCollection, sectionsLoading, sectionError] = useCollection(sectionQuery)




  const userTz = getLocalUTCTimezone()

  // next section index is based off the last section
  // TODO: This shouldn't be a useEffect, instead sectionNumber should be a computed value


  if (!isMinimumRole(courseRole, Role.TA)) {
    return <NoAccess />
  }




  if(!firstSectionTimestamp || !lastSectionTimestamp) return null
  if (sectionsLoading || !sectionCollection) return null

  return <div className="mt-4">
    <CoursePageSingleCol
      column={<SectionTables sectionCollection={sectionCollection}/>}
    />
  </div>
}

const SectionTables = ({ sectionCollection }) => {
  const userId = useUserId()
  const { userData } = useContext(ProfileContext)
  const courseContext = useContext(CourseContext);
  const serverTimeMs = useServerTime(30000)
  const courseId = useCourseId()
  const courseRole = userData?.courseRole
  const myInfo = {
    name: userData?.displayName ?? 'Unknown',
    userId: userId,
    courseRole: courseRole,
  }




  const sectionRows = []
  const needCoverRows = []

  sectionCollection.forEach((doc) => {
    // (doc, courseId, myInfo, courseRole, serverTimestamp, courseContext)
    const row = makeSectionTableRow(doc, courseId, myInfo, courseRole, serverTimeMs, courseContext)
    if (row) {
      if (row.needsCover) {
        needCoverRows.push(row)
      }
      sectionRows.push(row)
    }
  })

  const columns = [
    // {
    //   dataField: 'sectionId',
    //   text: 'Section ID',
    //   // sort: true
    // },
    {
      dataField: 'sectionPage',
      text: 'Page',
    },
    {
      dataField: 'timeIndex',
      text: 'Time Index',
      sort: true
    },
    {
      dataField: 'timeStr',
      text: 'Time (Your Timezone)',
      sort: true
    },
    // {
    //   dataField: 'sectionLeaderUID',
    //   text: 'SL ID',
    // }, 
    {

      dataField: 'nMembers',
      text: 'N',
      sort: true
    },
    {
      dataField: 'slStatus',
      text: 'SL RSVP',
      sort: true
    },
    {
      dataField: 'cover',
      text: 'Cover?',
      sort: true
    },
    {
      dataField: 'hasTeacher',
      text: 'Teacher?',
      sort: true
    },
    {
      dataField: 'zoomJoin',
      text: 'Student Link',
    },
    {
      dataField: 'zoomStart',
      text: 'SL Link',
    },
    {
      dataField: 'isMinor',
      text: 'Minor',
      sort: true
    },
    {
      dataField: 'manage',
      text: 'Manage'
    }]

  // Define the default sorting configuration
  const defaultSorted = [{
    dataField: 'timeIndex', // Field to sort by
    order: 'asc',           // Sort order: 'asc' for ascending, 'desc' for descending
  }];


  return <>
    <h3>Needs Cover</h3>
    <BootstrapTable
      key={'needs-cover'}
      data={needCoverRows}
      columns={columns}
      bordered={false}
      striped={true}
      hover={true}
      bootstrap4={true}
      keyField='id'
      defaultSorted={defaultSorted}
    />
    {/* <BootstrapTable bordered={false} striped={true} hover={true} bootstrap4={true} keyField='id' data={needCoverRows} columns={columns} /> */}
    <h3 className="mt-5">All Sections</h3>
    <BootstrapTable
      key={'all-sections'}
      data={sectionRows}
      columns={columns}
      bordered={false}
      striped={true}
      hover={true}
      bootstrap4={true}
      keyField='id'
      defaultSorted={defaultSorted}
    />
    {/* <BootstrapTable bordered={false} striped={true} hover={true} bootstrap4={true} keyField='id' data={sectionRows} columns={columns} /> */}
  </>
}

// Reafactor
export const makeSectionTableRow = (doc, courseId, myInfo, courseRole, serverTimestamp, courseContext) => {
  const { currCourseWeek, firstSectionTimestamp, lastSectionTimestamp } = courseContext;
  const userTz = getLocalUTCTimezone()

  const sectionWeek = currCourseWeek

  const sectionId = doc.id
  const section = doc.data();
  const name = section?.name ?? 'Unknown'
  const sectionLeaderId = section?.sectionLeader ?? ''
  const slRSVP = section?.sectionLeaderRSVP ?? {}
  const members = section?.members ?? []


  const weeksInMs = 7 * 24 * 60 * 60 * 1000 * (sectionWeek -1 )
  const firstSectionTimeDate = new Date(firstSectionTimestamp);
  const lastSectionTimeDate = new Date(lastSectionTimestamp);
  firstSectionTimeDate.setTime(firstSectionTimeDate.getTime() + weeksInMs)
  lastSectionTimeDate.setTime(lastSectionTimeDate.getTime() + weeksInMs)

  if (!section.isActive) {
    return null
  }

  // three states: Yes, No, Missing
  let slStatus = ''
  if (sectionWeek in slRSVP) {
    slStatus = slRSVP[sectionWeek] ? 'Confirmed' : 'Declined'
  }
  let timeIndex = section?.timeIndex ?? ''
  // try and convert it to a number, otherwise make it -1
  timeIndex = Number(timeIndex)
  // skip sections without a time
  if (timeIndex !== timeIndex) {
    return null
  }

  const zoomStartLink = section?.zoomStartLink ?? ''
  const zoomJoinLink = section?.zoomJoinLink ?? ''
  const needsCover = needsCoverCheck(section, sectionWeek, firstSectionTimeDate, serverTimestamp, lastSectionTimeDate)
  const teacherInZoom = hasActiveTeacher(section, currCourseWeek)
  
  return {
    id: sectionId,
    sectionId: <CopyButton toCopy={sectionId} icon={<FaCopy />} />,
    sectionLeaderUID: <CopyButton toCopy={sectionLeaderId} icon={<FaCopy />} />,
    slStatus,
    sectionPage: <SectionButton name={name} sectionId={sectionId} />,
    zoomStart: <EditableLink
      sectionId={sectionId}
      to={zoomStartLink}
      courseRole={courseRole}
      icon={<FaBolt />}
      docKey='zoomStartLink'
    />,
    zoomJoin: <EditableLink
      sectionId={sectionId}
      courseRole={courseRole}
      to={zoomJoinLink}
      icon={<FaVideo />}
      docKey='zoomJoinLink'
    />,
    cover: <CoverButton
      section={section}
      sectionId={sectionId}
      sectionNumber={sectionWeek}
      myInfo={myInfo}
    />,
    hasTeacher: teacherInZoom ? <FaCheck/> : <></>,
    timeIndex,
    nMembers: members?.length || 0,
    isMinor: section.isMinor,
    timeStr: getShortSectionTimeStr(userTz, timeIndex),
    needsCover,
    manage: <Link to={`/${courseId}/manage/${sectionId}`} className="btn btn-light btn-sm">
      <FaClipboard />
    </Link>
  }
}

const EditableLink = ({ courseRole, sectionId, to, icon, docKey }) => {

  const saveNewLink = (sectionId, newLink) => {
    const db = getFirestore(getApp())
    const sectionRef = doc(db, 'sections', sectionId)
    updateDoc(sectionRef, {
      [docKey]: newLink
    })
  }

  const onEdit = () => {
    Swal.fire({
      title: 'Edit Video Link: ' + docKey,
      input: 'text',
      inputValue: to,
      showCancelButton: true,
      inputValidator: (value) => {
        // empty is fine
        if (!value) return
        // check that its a valid zoom link
        if (!value.startsWith('https://')) {
          return 'Video link must start with https://'
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const newLink = result.value
        saveNewLink(sectionId, newLink)
      }
    })
  }
  const canEdit = isMinimumRole(courseRole, Role.INSTRUCTOR)
  const disabled = <span>{icon}</span>
  const enabled = <a className="btn btn-primary btn-sm" target="_blank" href={to}>{icon}</a>
  const linkComponent = to ? enabled : disabled
  return <>
    {linkComponent}
    {canEdit && <button onClick={onEdit} className="btn btn-sm btn-light ml-2">Edit</button>}
  </>
}
