import { Role } from "contexts/ProfileContext";
import { ForumContext } from "course/forum/ForumContext";
import { Forum } from "course/forum/pages/forum/Forum";
import { useCourseId } from "hooks/router/useUrlParams";
import { QueryClient, QueryClientProvider } from "react-query";
import styled from "styled-components";

const queryClient = new QueryClient();

export const TeachersLounge = ({}) => {
  return (
    <TeachersLoungeOuter>
      <BannerImg />
      <div
        className="d-flex justify-content-center flex-1"
        style={{ backgroundColor: ""}}
      >
        <div className="d-flex flex-row flex-1 w-100 h-100">
          <LoungeForum />
        </div>

        {/* <SectionCovers /> */}
      </div>
    </TeachersLoungeOuter>
  );
};

const BannerImg = styled.div`
  background-image: url(/course/img/loungeMor.jpg);
  width: 100%;
  height: 100px;
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #eee;
`

const TeachersLoungeOuter = styled.div`
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
`

const SectionCovers = () => {
  return (
    <>
      <div style={{ width: 220 }}>
        <div className="splashCardRightTitle p-2">Bulletin</div>
        {/* <div className="card">
        <div className="card-body">
          <>Help I need a cover!</>
        </div>
      </div> */}
      </div>
    </>
  );
};

const LoungeForum = () => {
  const courseId = useCourseId();
  const forumId = `${courseId}-teacherslounge`;

  return (
    <ForumContext.Provider
      value={{
        forumId: forumId,
        forumType: "Course",
        privatePostThreshold: Role.TA,
        setPinnedThreshold: Role.TA,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Forum />
      </QueryClientProvider>
    </ForumContext.Provider>
  );
};

