import { useContext } from "react"
import { PartialLoading } from "components/loading/Loading"
import { getApp } from "firebase/app"
import { collection, deleteField, doc, getFirestore, query, updateDoc, where } from "firebase/firestore"
import { useCourseId } from "hooks/router/useUrlParams"
import BootstrapTable from 'react-bootstrap-table-next';
import { useNavigate } from "react-router-dom"
import { FaTimes } from "react-icons/fa"
import Swal from "sweetalert2"
import { ProfileContext, Role } from "contexts/ProfileContext"
import { CourseContext } from "contexts/CourseContext"
import {
  getLocalUTCTimezone,
} from "components/timezones/timezoneHelper";
import { isMinimumRole } from "contexts/ProfileUtil"
import NoAccess from "components/errors/NoAccess"
import { useUserId } from "hooks/user/useUserId"
import { AdminRichTextEditor } from "course/components/AdminRichTextEditor"
import { CoursePageSingleCol } from "components/layout/CoursePageSingleCol"
import { makeSectionTableRow } from "./MonitorSections"
import { useCollection } from "react-firebase-hooks/firestore"
import { useServerTime } from "contexts/TimeContext"

export const SectionCovers = () => {
  const courseId = useCourseId()
  const { userData } = useContext(ProfileContext)
  const courseRole = userData?.courseRole
  const db = getFirestore(getApp())

  const sectionRef = collection(db, "sections")
  const sectionQuery = query(sectionRef, where("courseId", "==", courseId));
  const [sectionCollection, sectionsLoading, sectionError] = useCollection(sectionQuery)

  if (!isMinimumRole(courseRole, Role.MENTOR)) {
    return <NoAccess />
  }

  if (!sectionCollection) return <PartialLoading />

  // return <div className="mt-4">
  //   <SectionTables sectionCollection={sectionCollection} />
  // </div>
  return <div className="mt-4">
    <CoursePageSingleCol
      column={<SectionTables sectionCollection={sectionCollection} />}
    />
  </div>
}

const SectionTables = ({ sectionCollection }) => {
  const userId = useUserId()
  const { userData } = useContext(ProfileContext)
  const courseContext = useContext(CourseContext)
  const { currCourseWeek, firstSectionTimestamp } = courseContext;
  const firstSectionTimestampDate = new Date(firstSectionTimestamp)
  const serverTimeMs = useServerTime(30000)

  // we need to know if the user is a minor
  const isUserMinor = userData?.isMinor || false
  // alert(isUserMinor)

  const courseId = useCourseId()
  const courseRole = userData?.courseRole
  const myInfo = {
    name: userData?.displayName ?? 'Unknown',
    userId: userId,
    courseRole: courseRole,
  }


  const needCoverRows = []

  // For all sections
  sectionCollection.forEach((doc) => {
    // get time index and hour delta
    const timeIndex = doc.data().timeIndex
    if(timeIndex===undefined) { return }
    const hours = timeIndex * 1000 * 60 * 60
    // recall currCourseWeek is "1-indexed"
    const weeks = (currCourseWeek-1) * 7 * 24 * 60 * 60 * 1000
    const sectionDate = new Date(firstSectionTimestampDate.getTime() + hours + weeks)
    if(isNaN(sectionDate.getTime())) { return }

    const row = makeSectionTableRow(doc, courseId, myInfo, courseRole, serverTimeMs, courseContext)

    if (row) {
      const isSectionMinor = row.isMinor
      if (row.needsCover) {
        // we dont need an admin override here -- admins can cover via manage section
        if(isUserMinor == isSectionMinor){
          needCoverRows.push(row)
        }
      }
    }
  })

  // Define the default sorting configuration
  const defaultSorted = [{
    dataField: 'timeIndex', // Field to sort by
    order: 'asc',           // Sort order: 'asc' for ascending, 'desc' for descending
  }];

  return <>
    <h3>Section Covers</h3>
    <div style={{ maxWidth: 900, paddingRight: '10px' }}><AdminRichTextEditor
      firebaseDocPath={`course/${courseId}/handouts/sectioncoversinfo`}
    />
    </div>
    <h3>Covers Needs</h3>
    <BootstrapTable
      bordered={false}
      striped={true}
      hover={true}
      bootstrap4={true}
      keyField='id'
      data={needCoverRows}
      columns={columns}
      defaultSorted={defaultSorted}
    />
  </>
}


const EditableLink = ({ courseRole, sectionId, to, icon, docKey }) => {

  const saveNewLink = (sectionId, newLink) => {
    const db = getFirestore(getApp())
    const sectionRef = doc(db, 'sections', sectionId)
    updateDoc(sectionRef, {
      [docKey]: newLink
    })
  }

  const onEdit = () => {
    Swal.fire({
      title: 'Edit Video Link: ' + docKey,
      input: 'text',
      inputValue: to,
      showCancelButton: true,
      inputValidator: (value) => {
        // empty is fine
        if (!value) return
        // check that its a valid zoom link
        if (!value.startsWith('https://')) {
          return 'Video link must start with https://'
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const newLink = result.value
        saveNewLink(sectionId, newLink)
      }
    })
  }
  const isStaff = isMinimumRole(courseRole, Role.TA)
  const disabled = <span>{icon}</span>
  const enabled = <a className="btn btn-primary btn-sm" target="_blank" href={to}>{icon}</a>
  const linkComponent = to ? enabled : disabled
  return <>
    {linkComponent}
    {isStaff && <button onClick={onEdit} className="btn btn-sm btn-light ml-2">Edit</button>}
  </>
}


const SectionButton = ({ name, sectionId }) => {
  const navigate = useNavigate()
  const courseId = useCourseId()
  // when you click on the name, it copies the sectionLeaderId to your clipboard
  const onCopy = () => {
    navigate(`/${courseId}/section/${sectionId}`)
  }
  return <button onClick={onCopy} className="btn btn-sm btn-light">{name}</button>

}

const CoverButton = ({ section, sectionId, sectionNumber, myInfo }) => {
  const userId = useUserId()
  const covers = section?.covers
  const { userData } = useContext(ProfileContext)

  const initiateCover = () => {
    const db = getFirestore(getApp())
    const sectionRef = doc(db, 'sections', sectionId)
    updateDoc(sectionRef, {
      covers: {
        [sectionNumber]: myInfo
      }
    })
  }

  const cancelCover = () => {
    const db = getFirestore(getApp())
    const sectionRef = doc(db, 'sections', sectionId)
    updateDoc(sectionRef, {
      [`covers.${sectionNumber}`]: deleteField()
    })
  }

  const initiateCancelCover = () => {
    Swal.fire({
      title: 'Cancel Cover',
      text: 'Are you sure you want to cancel covering this section? You should only do this if you are the person who is currently covering this section, or a course admin.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, we need a different person to cover this section',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        cancelCover()
      }
    })
  }

  const onClick = () => {
    Swal.fire({
      title: 'Cover Section',
      text: 'Are you sure you want to cover this section?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, cover it!',
      cancelButtonText: 'No',
      reverseButtons: true

    }).then((result) => {
      if (result.isConfirmed) {
        initiateCover()
      }
    })
  }

  if (covers && covers[sectionNumber]) {
    const coverInfo = covers[sectionNumber]
    const tag = coverInfo?.name ?? 'Anon'
    const coveringUserId = coverInfo?.userId
    const isCovering = coveringUserId === userId
    const isAdmin = isMinimumRole(userData.courseRole, Role.TA)
    const canEditCover = isCovering || isAdmin

    // render a delete button next to the tag
    return <>{tag}
      {" "}
      {canEditCover && <button onClick={initiateCancelCover} className="btn btn-sm btn text-danger" aria-label="Close"><FaTimes /></button>}</>
  }
  if (needsCover(section, sectionNumber)) {
    return <button onClick={onClick} className="btn btn-sm btn-primary">I Will Cover</button>
  }
  return <></>
}

function needsCover(section, sectionNumber, nextSectionDate) {
  // 
  // const covers = section?.covers
  // if (covers){
  //   // don't need a cover if there already is one
  //   if(covers[sectionNumber]?.name) return false
  // }

  // don't need a cover if there is an RSVP from the SL
  const slRSVP = section?.sectionLeaderRSVP
  const hasRSVP = slRSVP && (sectionNumber in slRSVP)

  // how long until the section?
  const timeIndex = section?.timeIndex
  const userTz = getLocalUTCTimezone()
  const timeUntilMs = nextSectionDate - Date.now()
  const timeUntilMins = timeUntilMs / 1000 / 60


  // if the RSVP is false, we need a cover
  if (hasRSVP) {
    // if there is ever a false RSVP, we need a cover 
    if (!slRSVP[sectionNumber]) {
      return true
    }
  } else {
    // if there is no RSVP, we need a cover if the section is less than 6 hours away
    if (timeUntilMins < 6*60) {
      return true
    }
  }

  // if we are 5 mins passed time, and the section leader has not shown up, we need a cover
  if (timeUntilMins < -5) {
    // did the SL show up?
    // but instead we will just dispurse the students
  }



  // otherwise, we dont need a cover
  return false
}

const columns = [
  {
    dataField: 'sectionPage',
    text: 'Section Page',
  },
  {
    dataField: 'cover',
    text: 'Has Cover?',
  },
  {
    dataField: 'timeStr',
    text: 'Section Start',
    sort: true
  },
  {
    dataField: 'slStatus',
    text: 'SL RSVP',
    sort: true
  },
  {
    dataField: 'timeIndex',
    text: 'Time Index',
    sort: true
  }
]