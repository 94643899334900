import { useState, useEffect, useContext } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer";
import { PartialLoading } from "components/loading/Loading";
import { ProfileContext } from "contexts/ProfileContext";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { useCourseId } from "hooks/router/useUrlParams"
import { Spacer } from "components/layout/Spacer";
import { getLocalUTCTimezone } from 'components/timezones/timezoneHelper';
import { FaCheck, FaWindowClose} from 'react-icons/fa';
import { parseAndFormatISODate } from 'components/timezones/timezoneHelper';

const functions = getFunctions();
const getGradesForOneStudent = httpsCallable(functions, 'getGradesForOneStudent');
const getAllAssnGradesForOneStudent = httpsCallable(functions, 'getAllAssnGradesForOneStudent');


export const StudentGradesPage= () => {
    return (
        <CoursePageBodyContainer
            mainColumn={<StudentGradesMain/>}
            singleColumn={<StudentGradesMain/>}
            rightColumn={<></>}
        />
    )

}


const StudentGradesMain = () => {
    const courseId = useCourseId()
    const {userData} = useContext(ProfileContext);
    const userId = userData.id
    const [studentCurrentScore, setStudentCurrentScore] = useState(-1)
    const [studentCurrentGrade, setStudentCurrentGrade] = useState("")
    const [studentAssnGrades, setStudentAssnGrades] = useState([])

    useEffect(() => {

        const fetchGrades = async () => {
            console.log("Fetching grades")
            const response = await getGradesForOneStudent({
                userId: userId,
                courseId: courseId
            })
            if(!response || !response.data) {
                // handle error
                console.log("Error fetching grades")
                return
            }
            setStudentCurrentScore(response.data.grades.current_score)
            setStudentCurrentGrade(response.data.grades.current_grade)

        }
        fetchGrades()

        const fetchAssnGrades = async () => {
            console.log("Fetching assignment grades")
            const response = await getAllAssnGradesForOneStudent({
                userId: userId,
                courseId: courseId
            })
            if(!response || !response.data) {
                // handle error
                console.log("Error fetching assignment grades")
                return
            }
            console.log("Assignment grades")
            console.log(response.data)
            setStudentAssnGrades(response.data)
        }
        fetchAssnGrades()
    }, [userId, courseId])

    if (studentCurrentScore < 0 || studentAssnGrades.length === 0) {
        return <PartialLoading />
    }

    return <>
        <Spacer />
        <h3>Your Grades</h3>
        <h4>
            Current Grade: {studentCurrentScore}% 
            {studentCurrentGrade && ` (${studentCurrentGrade})`}
        </h4>
        <StudentGradesTable studentAssnGrades={studentAssnGrades} />
    </>
}


const StudentGradesTable = ({ studentAssnGrades }) => {
    const userTz = getLocalUTCTimezone()

    const columns = [
        { dataField: 'name', text: 'Name' },
        { 
            dataField: 'due_at', 
            text: 'Due',
            formatter: (cell) => {
                if (!cell) {
                    return "N/A";
                }
                return parseAndFormatISODate(cell, userTz);
            }
        },
        { 
            dataField: 'submitted', 
            text: 'Submitted',
            formatter: (cell) => {
                return cell ? <FaCheck /> : <FaWindowClose />
            }
        },
        { dataField: 'score', text: 'Score' },
    ]

    // TODO: we could also add more UI elements to show, e.g., grade_matches_current_submission, late, workflow_state (all in assn.submission)
    const data = studentAssnGrades.map(assn => {
        const submitted = 'submission' in assn
        const pointsPossible = assn.points_possible
        let scoreString = `- / ${pointsPossible}`
        if (submitted && 'grade' in assn.submission && assn.submission.grade !== null) {
            scoreString = `${assn.submission.grade} / ${pointsPossible}`
        }
        return {
            name: assn.name,
            due_at: assn.due_at,
            submitted: 'submission' in assn,
            score: scoreString,
        } 
    })

    return <BootstrapTable
        bordered={false}
        striped={true}
        hover={true}
        bootstrap4={true}
        keyField='name'
        data={data}
        columns={columns} />

}