import { useParams } from "react-router-dom";
import { useContext, useState, useEffect } from "react"
import { useFirebaseDocOnce } from "../../hooks/database/useFirebaseDocOnce"
import { CoursePageBodyContainer } from "../../components/layout/CoursePageBodyContainer"
import { LocationInfo } from "../../components/countries/LocationInfo"
import { Row, Col, Badge } from "react-bootstrap"
import { useCourseId } from 'hooks/router/useUrlParams';
import { Role } from "../../contexts/ProfileContext"
import { firebaseStringToRole, roleToString } from "contexts/ProfileUtil";
import { FaLink } from "react-icons/fa"
import _ from 'lodash'
import { ProfileContext } from "../../contexts/ProfileContext";
import { ConnectionsButton } from "../../course/connections/components/ConnectButtons";
import { ConnectionProfileWidget } from "../../course/connections/ConnectionProfileWidget";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { Spacer } from "../../components/layout/Spacer";
import { ProfilePicEditor } from "./editors/ProfilePicEditor";
import styled from "styled-components";
import { ViewOnlyProfilePicture } from "./components/ViewOnlyProfilePicture";
import { UserInfoRow } from "./components/UserInfoRow";

export const ViewOnlyProfile = (props) => {
  const { userId } = useParams() // get the user id from the url (this is the user whose profile one is viewing)
  const courseId = useCourseId()
  const userPath = `users/${userId}`
  const [userIsLoading, userData] = useFirebaseDocOnce(userPath, {})
  const userRolePath = `users/${userId}/roles/${courseId}`
  const [roleIsLoading, roleData] = useFirebaseDocOnce(userRolePath, {})
  const { userData: viewerUserData } = useContext(ProfileContext);
  const viewerId = viewerUserData.id;
  const [toggle, setToggle] = useState('') // different toggles for different statuses of the connection

  if (userIsLoading || roleIsLoading) return <></>
  if (userData == {}) return <div> "The user you are looking for does not exist!" </div>


  return <CoursePageBodyContainer
    mainColumn={<ViewOnlyProfileMainColumn
      userId={userId}
      userData={userData}
      courseId={courseId}
      role={roleData.role}
      viewerId={viewerId}
      viewerUserData={viewerUserData}
      toggle={toggle}
      setToggle={setToggle}
    />}
    singleColumn={<ViewOnlyProfileMainColumn
      userId={userId}
      userData={userData}
      courseId={courseId}
      role={roleData.role}
      viewerId={viewerId}
      viewerUserData={viewerUserData}
      toggle={toggle}
      setToggle={setToggle}
    />}
    rightColumn={<></>}
  />

}

const ViewOnlyProfileMainColumn = ({
  userId,
  userData,
  courseId,
  role,
  viewerId,
  viewerUserData,
  toggle,
  setToggle,
}) => {
  const hasConnections = courseId === 'cip4' // TODO miranda!
  let courseRoleEnum = firebaseStringToRole(role)
  if (courseId === "public" && courseRoleEnum === Role.UNREGISTERED) {
    courseRoleEnum = Role.STUDENT
  }
  const courseRole = roleToString(courseRoleEnum)

  const db = getFirestore()

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState('notConnected');
  const [connectionData, setConnectionData] = useState(null);

  useEffect(() => {
    // Get status of connection
    const getStatus = async (viewerId, userId) => {
      const connectionRef = doc(db, `users/${viewerId}/docs/protected/connections/${userId}`)
      const sentRequestRef = doc(db, `users/${viewerId}/docs/protected/sentRequests/${userId}`)
      const receivedRequestRef = doc(db, `users/${viewerId}/docs/protected/receivedRequests/${userId}`)
      const dismissedRequestRef = doc(db, `users/${viewerId}/docs/protected/dismissedRequests/${userId}`)

      // Fetch docs
      const connectionDoc = await getDoc(connectionRef);
      const sentRequestDoc = await getDoc(sentRequestRef);
      const receivedRequestDoc = await getDoc(receivedRequestRef);
      const dismissedRequestDoc = await getDoc(dismissedRequestRef);

      const connectionData = connectionDoc.exists() ? connectionDoc.data() : {};
      const sentRequestData = sentRequestDoc.exists() ? sentRequestDoc.data() : {};
      const receivedRequestData = receivedRequestDoc.exists() ? receivedRequestDoc.data() : {};
      const dismissedRequestData = dismissedRequestDoc.exists() ? dismissedRequestDoc.data() : {};

      // Check status
      if (!_.isEmpty(connectionData)) {
        return setStatus('connected'), setConnectionData(connectionData), setLoading(false);
      }
      if (!_.isEmpty(sentRequestData)) {
        return setStatus('sentRequest'), setConnectionData(sentRequestData), setLoading(false);
      }
      if (!_.isEmpty(receivedRequestData)) {
        return setStatus('receivedRequest'), setConnectionData(receivedRequestData), setLoading(false);
      }
      if (!_.isEmpty(dismissedRequestData)) {
        return setStatus('dismissedRequest'), setConnectionData(dismissedRequestData), setLoading(false);
      }
      return setStatus('notConnected'), setConnectionData(null), setLoading(false);
    }

    getStatus(viewerId, userId);
  }, []);

  if (hasConnections && !userData?.isMinor && !viewerUserData?.isMinor && userData?.wantToConnect) {
    // hasConnections tells us the course has connections enabled
    // other checks tell us this user wants to connect and neither the viewer nor the owner of the profile are minors, show profile with connect functionality
    return (<>
      <Row className="mt-3">
        <Col xs="auto">
          <ViewOnlyProfilePicture userData={userData} />
        </Col>
        <Col xs className="d-flex align-items-center">
          <div>
            <NameRoleRow>
                {userData?.displayName}
                &nbsp;
                <LocationInfo country={userData.country} height={18} />
                &nbsp;
                <Badge bg='secondary' style={{ fontSize: '0.4em' }}>{courseRole}</Badge>
            </NameRoleRow>
            <UserInfoRow userId={userId} userData={userData} viewerId={viewerId} viewerUserData={viewerUserData} />
            <ConnectionsButton
              viewerId={viewerId}
              userId={userId}
              loading={loading}
              status={status}
              connectionData={connectionData}
              toggle={toggle}
              setToggle={setToggle}
            />
          </div>
        </Col>
      </Row>
      <ConnectionProfileWidget
        viewerId={viewerId}
        userId={userId}
        userData={userData}
        loading={loading}
        status={status}
        connectionData={connectionData}
        toggle={toggle}
        setToggle={setToggle}
        setStatus={setStatus}
        setConnectionData={setConnectionData}
      />
      <hr />
      {userData.aboutMe && <AboutMeSection userData={userData} />}
      {userData.goals && <GoalsSection userData={userData} />}
      {/* TODO uncomment when portfolio bug is fixed */}
      {/* {userData.portfolio && <PortfolioSection userData={userData} courseId={courseId}/>} */}
      <Spacer />
    </>
    )
  }

  // this user does not want to connect, show profile with no connect functionality
  return (<>
    <Row className="mt-3">
      <Col xs="auto">
        <ViewOnlyProfilePicture userData={userData} />
      </Col>
      <Col xs className="d-flex align-items-center">
        <div>
          <h1>
            {userData?.displayName}
            &nbsp;
            <LocationInfo country={userData.country} height={18} />
            &nbsp;
            <Badge bg='secondary' style={{ fontSize: '0.4em' }}>{courseRole}</Badge>
          </h1>
        <UserInfoRow userId={userId} userData={userData} viewerId={viewerId} viewerUserData={viewerUserData} />
        </div>
      </Col>
    </Row>
    <hr />
    {userData.aboutMe && <AboutMeSection userData={userData} />}
    {userData.goals && <GoalsSection userData={userData} />}
    {/* TODO uncomment when portfolio bug is fixed (see Profile) */}
    {/* {userData.portfolio && <PortfolioSection userData={userData} courseId={courseId}/>} */}
    <Spacer />
  </>
  )
}

const AboutMeSection = ({ userData }) => {
  return <div>
    <h4>About Me</h4>
    <ProfileText>{userData.aboutMe}</ProfileText>
  </div>
}

const GoalsSection = ({ userData }) => {
  return <div>
    <h4>Goals</h4>
    <ProfileText>{userData.goals}</ProfileText>
  </div>
}

const PortfolioSection = ({ userData, courseId }) => {
  return <div>
    <h4>Portfolio</h4>
    <div className="w-75 d-flex flex-column flex-wrap justify-content-center">
      {userData.portfolio.map((project, index) =>
        // TODO: would be nice to show the name of the project here
        <div key={index}><a href={`/${courseId}/share/${project}`}><FaLink /> Portfolio project {index + 1}</a></div>
      )}
    </div>
  </div>
}


const NameRoleRow = styled.h1`
  display: flex;
  align-items: center; 
`

const ProfileText = styled.p`
  width: 100%;
  max-width: 500px;

  

`