import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2"
import { getFunctions, httpsCallable } from "firebase/functions";
import { RoleSelect } from "./RoleSelect"
import { IsMinorSelect } from "./IsMinorSelect"
import { useCourseId } from '../../hooks/router/useUrlParams';
import { roleToFirebaseString } from "../../contexts/ProfileUtil";

type ChangeDisplayNameParams = {
    userId : string,
    courseId : string,
    newDisplayName: string, 
}

type ChangeEmailParams = {
    userId : string,
    courseId : string,
    newEmail: string, 
}

type ChangeRoleParams = {
    userId : string,
    courseId : string,
    newRole: string, 
}

type ChangeIsMinorParams = {
    userId : string,
    courseId : string,
    newIsMinor : boolean, 
}

type ChangePermissionsResult = {
    success: boolean
    reason?: string
}

export const EditRowForm = ({
    showEditRowForm, 
    handleClose, 
    uid, 
    displayName, 
    email, 
    role, 
    isMinor,
    resetAndRefresh
} : {
    showEditRowForm: boolean,
    handleClose: Function,
    uid: string,
    displayName: string,
    email: string,
    role: string,
    isMinor: boolean,
    resetAndRefresh: Function
}) => {
    const courseId = useCourseId()
    const [localDisplayName, setLocalDisplayName] = useState(displayName)
    const [localEmail, setLocalEmail] = useState(email)
    const [localRole, setLocalRole] = useState(role)
    const [localIsMinor, setLocalIsMinor] = useState(String(isMinor))
    const [dirtyBit, setDirtyBit] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    
    const functions = getFunctions(); // firebase fns for saving changes
    const changeDisplayNameFirebase = httpsCallable<ChangeDisplayNameParams,ChangePermissionsResult>(functions, "changeDisplayName");
    const changeEmailFirebase = httpsCallable<ChangeEmailParams,ChangePermissionsResult>(functions, "changeEmail")
    const changeRoleFirebase = httpsCallable<ChangeRoleParams,ChangePermissionsResult>(functions, "changeRole")
    const changeIsMinorFirebase = httpsCallable<ChangeIsMinorParams,ChangePermissionsResult>(functions, "changeIsMinor")
 
    useEffect(() => {
        const same = (
            localDisplayName === displayName &&
            localEmail === email &&
            localRole === role &&
            localIsMinor === String(isMinor)
        )
        setDirtyBit(!same)
    }, [localDisplayName, localEmail, localRole, localIsMinor])

    const onDisplayNameChange = (e) => {
        setLocalDisplayName(e.target.value)
    }
    const onEmailChange = (e) => {
        setLocalEmail(e.target.value)
    }
    
    const saveChanges = async () => {
        setIsSaving(true)
        let errorText = ""
        if (localDisplayName !== displayName) {
            // save new display name
            const res = await changeDisplayNameFirebase({userId : uid, courseId, newDisplayName : localDisplayName})
            if (!res.data.success){
                // something went wrong
                errorText = errorText + res.data.reason! + "</br>"
            }
        }
        if (localEmail !== email){
            // save new email
            const res = await changeEmailFirebase({userId : uid, courseId, newEmail : localEmail})
            if (!res.data.success){
                // something went wrong
                errorText = errorText + res.data.reason! + "</br>"
            } 
        }
        if (localRole !== role) {
            // save new role
            const res = await changeRoleFirebase({userId : uid, courseId, newRole : roleToFirebaseString(parseInt(localRole))})
            if (!res.data.success){
                // something went wrong
                errorText = errorText + res.data.reason! + "</br>"
            } 
        }
        if (localIsMinor !== String(isMinor)){
            // save new isMinor field
            const res = await changeIsMinorFirebase({userId : uid, courseId, newIsMinor : JSON.parse(localIsMinor)})
            if (!res.data.success){
                // something went wrong
                errorText = errorText + res.data.reason! + "</br>"
            } 
        }

        if (errorText !== ""){
            Swal.fire({
                title: 'Something went wrong!',
                html: errorText,
                icon: 'error',
            })
        } else {
            Swal.fire({
                title: 'Success!',
                text: 'User info changed successfully.',
                icon: 'success',
            })
        }

        setDirtyBit(false)
        setIsSaving(false)
        handleClose()
        resetAndRefresh()
    }

    return (
    <Modal show={showEditRowForm} onHide={() => {handleClose()}}>
        <Modal.Header closeButton>
          <Modal.Title>Edit user info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
            <Form.Text>UID: {uid}</Form.Text>
            <Form.Group>
                <Form.Label>Display name</Form.Label>
                <Form.Control type="text" value={localDisplayName} onChange={onDisplayNameChange}/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" value={localEmail} onChange={onEmailChange}/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Role</Form.Label>
                <RoleSelect role={localRole} setRole={setLocalRole} includeDefault={false}/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Age group</Form.Label>
                <IsMinorSelect setIsMinor={setLocalIsMinor} defaultVal={String(isMinor)}/>
            </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e) => {handleClose}}>
            Close
          </Button>
          <Button variant="primary" onClick={saveChanges} disabled={!dirtyBit || isSaving}>
            {isSaving ? 'Saving...' : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>
      )
}