import StudentFeedbackComponents from "./StudentFeedbackComponents";
import SectionLeaderFeedbackComponents from "./SectionLeaderFeedbackComponents";
import { CoursePageBodyContainer } from "../../components/layout/CoursePageBodyContainer";
import { useParams } from "react-router-dom";
import { ProfileContext, Role } from "contexts/ProfileContext";
import { useContext } from "react";
import StaticStudentFeedback from "./components/StaticStudentFeedback";
import { ExperimentContext } from "contexts/ExperimentContext";

export const SectionFeedbackPage = (props) => {

  const { userData } = useContext(ProfileContext);

  const { sectionWeek, sectionId } = useParams();
  const { expData, expLoading } = useContext(ExperimentContext);

  // get the user's role
  const courseRole = userData?.courseRole;

  if (expLoading) return <></>;
  
  const treatmentGroup = expData?.transcriptFeedback || "StudentSLFeedback";

  const feedbackInner = (
    <SectionWiseFeedback
      role={courseRole}
      week={sectionWeek}
      sectionId={sectionId}
      group={treatmentGroup}
    />
  );

  return (
    <CoursePageBodyContainer
      mainColumn={feedbackInner}
      rightColumn={<></>}
      singleColumn={feedbackInner}
    />
  );
};

const SectionWiseFeedback = ({ role, week, sectionId, group }) => {
  console.log(role === Role.STUDENT);
  console.log(group === "StudentFeedback" || group === "StudentSLFeedback");

  const inExpGroup =
    group === "StudentFeedback" || group === "StudentSLFeedback";

  if (role === Role.STUDENT) {
    if (inExpGroup) {
      return (
        <StudentFeedbackComponents
          week={week}
          sectionId={sectionId}
          group={group}
        />
      );
    } else {
      return <StaticStudentFeedback />;
    }
  }

  return <SectionLeaderFeedbackComponents week={week} sectionId={sectionId} />;
};
