import { Link, useNavigate, useParams } from "react-router-dom"
import { NewProjectProposalButton } from "./NewProjectProposalButton"
import { Dropdown } from "react-bootstrap"
import { OverlaySimple } from "components/overlay/OverlaySimple"
import { FaEdit, FaPlus, FaTrash, FaEllipsisH, FaStar } from "react-icons/fa"
import styled from "styled-components"
import { lightPurple } from "styles/colors"
import { useContext } from "react"
import { FinalProjectContext, finalProjectCollection } from "./FinalProjectContext"
import { useCourseId } from "hooks/router/useUrlParams"
import { deleteDoc, doc, getFirestore, serverTimestamp, updateDoc } from "firebase/firestore"
import { useUserId } from "hooks/user/useUserId"
import Swal from "sweetalert2"


export const FinalProjectDropdown = () => {
  const { projects, createProjectAndNavigate } = useContext(FinalProjectContext)
  const sortedProjects = useSortedProjects()
  const courseId = useCourseId()
  const userId = useUserId()
  const projectId = useParams().proposalId
  const docPath = `finalProjects/${courseId}/${userId}/${projectId}`;
  const docRef = doc(getFirestore(), docPath);


  // a dropdown version of the final project list
  return <NoCarrot><Dropdown>


    <Dropdown.Toggle as="button" className="btn btn-light">
      <FaEllipsisH />
    </Dropdown.Toggle>

    <Dropdown.Menu>
      {
        sortedProjects.map(projectId => {
          const data = projects[projectId]
          const name = getProjectName(data)
          const toLink = `/${useCourseId()}/finalProjectProposal/${projectId}`
          return <Dropdown.Item key={projectId} as={Link} to={toLink}>{name}</Dropdown.Item>
        })
      }
      <Dropdown.Divider />
      <Dropdown.Item onClick={createProjectAndNavigate}><FaPlus /> Create a new proposal</Dropdown.Item>
      <Dropdown.Item onClick={() => renameItem(docRef)}><FaEdit /> Rename proposal</Dropdown.Item>
      <Dropdown.Item onClick={() => makePrimary(docRef)}><FaStar /> Make primary</Dropdown.Item>
      <Dropdown.Item onClick={() => deleteItem(docRef)}><FaTrash /> Delete</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown></NoCarrot>
}


export const FinalProjectList = () => {

  return <div className="mt-3 w-100">
    <div className="splashCardRightTitle" style={{ paddingLeft: 10 }}>Proposals</div>
    <ProjectIdeas />
    <div style={{ height: 10 }}></div>
    <NewProjectProposalButton />
  </div>
}

const ProjectIdeas = () => {
  const { projects } = useContext(FinalProjectContext)
  const sortedProjects = useSortedProjects()

  return <ProjectIdeasOuter>
    {
      sortedProjects.map(projectId => {
        return <ProjectItem
          key={projectId}
          data={projects[projectId]}
          projectId={projectId}
        />
      })

    }
  </ProjectIdeasOuter>
}

const ProjectItem = ({ data, projectId }) => {
  const currProjectId = useParams().proposalId
  const isSelected = currProjectId === projectId
  const navigate = useNavigate()
  const courseId = useCourseId()
  const userId = useUserId()
  const name = getProjectName(data)
  const toLink = `/${courseId}/finalProjectProposal/${projectId}`
  const docPath = `finalProjects/${courseId}/${userId}/${projectId}`;
  const docRef = doc(getFirestore(), docPath);

  

  const outerStyle = {
    backgroundColor: isSelected ? lightPurple : 'white'
  }

  return <ListItemOuter style={outerStyle}>
    <ProjectLink to={toLink}><span style={{ textOverflow: 'ellipsis' }}> {name}</span></ProjectLink>
    <Dropdown align="end">
      <OverlaySimple overlayText="Options" placement="top">
        <Dropdown.Toggle as="button" className="btn btn-sm">
          <FaEllipsis />
        </Dropdown.Toggle>
      </OverlaySimple>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => makePrimary(docRef, navigate, toLink)}><FaStar /> Make Primary</Dropdown.Item>
        <Dropdown.Item onClick={() => renameItem(docRef)}><FaEdit /> Rename</Dropdown.Item>
        <Dropdown.Item onClick={() => deleteItem(docRef)}><FaTrash /> Delete</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </ListItemOuter>
}

const useSortedProjects = () => {
  // dictionary from projectId to projectData
  const { projects } = useContext(FinalProjectContext)

  let primaryProjectId = null
  let primarySetAt = null
  for (const projectId in projects) {
    if (projects[projectId].primarySetAt) {
      const currPrimarySetAt = projects[projectId].primarySetAt
      if (!primaryProjectId || currPrimarySetAt > primarySetAt) {
        primaryProjectId = projectId
        primarySetAt = currPrimarySetAt
      }
    }
  }


  const sortedProjects = Object.keys(projects).sort((a, b) => {
    // primary project should be first
    if (a === primaryProjectId) return -1
    // Check if 'b' is the primaryProjectId, it should come first
    if (b === primaryProjectId) return 1;

    return projects[b].createdAt - projects[a].createdAt
  })

  return sortedProjects
}


function getProjectName(data) {
  // first use a name if one exists
  if (data.name) return data.name

  // else, use the projectIdea
  if (data.projectIdea) return data.projectIdea

  // else return "New Project"
  return "New Project"
}

const deleteItem = (docRef) => {
  // first fire a swal to confirm
  Swal.fire({
    title: "Are you sure?",
    text: "This will delete the project proposal. You can't undo this action.",
    icon: "warning",
    reverseButtons: true,
    showCancelButton: true,
    confirmButtonText: "Delete",
    confirmButtonColor: "red",
    cancelButtonText: "Cancel",

  }).then((result) => {
    if (result.isConfirmed) {
      // remove the document from firebase
      deleteDoc(docRef)
    }
  })

}

const makePrimary = async (docRef, navigate, toLink) => {
  await updateDoc(docRef, {
    primarySetAt: serverTimestamp()
  });
  if(toLink){
    navigate(toLink)
  }
}

const renameItem = (docRef) => {
  Swal.fire({
    title: "Name this project proposal",
    input: "text",
    showCancelButton: true,
    confirmButtonText: "Save",
    showLoaderOnConfirm: true,
    preConfirm: async (newName) => {

      // names can't be too short
      if (newName.length < 3) {
        Swal.showValidationMessage("Name must be at least 3 characters")
      }

      // write the name to the document
      await updateDoc(docRef, {
        name: newName
      });

    },
    allowOutsideClick: () => !Swal.isLoading()
  })
}

const ProjectLink = styled(Link)`
  text-overflow: clip;
  overflow: hidden; // You might need this for text-overflow to work
  white-space: nowrap; // This is also often necessary with text-overflow
  display: block; // or inline-block, depending on your layout needs
`


const ProjectIdeasOuter = styled.div`
  display: flex;
  flex-direction: column;
`


const ListItemOuter = styled.div`
  padding:5px;
  padding-left: 10px;
  width:100%;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  &:hover {
    background-color: ${lightPurple};
  }

  .dropdown-toggle::after {
    display: none;
  }
`

const NoCarrot = styled.span`
.dropdown-toggle::after {
  display: none !important;
}
`
