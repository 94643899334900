import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { CoursePageBodyContainer } from "../../../components/layout/CoursePageBodyContainer"
import { loadAssnData } from "../../../ide/hooks/loadIdeData"
import {
  doc,
  deleteDoc,
  getFirestore,
  setDoc,
  deleteField,
} from "firebase/firestore"
import { TipTap } from "../../../components/richTextEditor/TipTap/TipTap"
import {
  DropdownButton,
  Dropdown,
  Button,
  FormControl,
  InputGroup,
  Badge,
  CloseButton,
} from "react-bootstrap"
import { MonacoTipTap } from "components/richTextEditor/TipTap/extensions/runnableKarel/TipTapCodeEditor"
import { AssnEditorSplash } from "./AssnEditorSplash"
import { EditUnitTests } from "./editors/EditUnitTests"
import { EditHiddenExamples } from "./editors/EditHiddenExamples"
import { EditRandomRegex } from "./editors/EditRandomRegex"
import { AssnEditorButtonBar } from "components/richTextEditor/TipTap/buttonbars/AssnEditorButtonBar"
import { useFilePicker } from "use-file-picker"
import { FaPlay, FaUpload } from "react-icons/fa"
import { ProfileContext } from "../../../contexts/ProfileContext"
import Gate from "contexts/Gate"
import NoAccess from "components/errors/NoAccess"
import { useHistoryNavigate } from "hooks/router/useHistoryNavigate"

export const AssnEditor = (props) => {
  const { userData } = useContext(ProfileContext)
  
  const isAdmin = Gate.hasAdminRole(userData)
  
  if (!isAdmin) {
    return <NoAccess />
  }
  const { courseId, assnId } = useParams()
  if (!assnId || assnId == "splash") {
    return <AssnEditorSplash />
  }
  const [isLoading, assnData] = loadAssnData(courseId, assnId, () => {
    createNewAssignment(courseId, assnId, "Untitled", "karel", () => { })
  })

  if (isLoading) {
    return <></>
  }


  return (
    <CoursePageBodyContainer
      mainColumn={
        <AssnEditorMain
          serverAssnData={assnData}
          assnId={assnId}
          courseId={courseId}
        />
      }
      rightColumn={<></>}
      singleColumn={
        <AssnEditorMain
          serverAssnData={assnData}
          assnId={assnId}
          courseId={courseId}
        />
      }
    />
  )
}

export const AssnEditorMain = (props) => {

  const { courseId, assnId } = useParams()
  const navigate = useHistoryNavigate()
  const assnPath = `/${courseId}/ide/a/${assnId}`
  const [workedExampleCache, setWorkedExampleCache] = useState(
    props.serverAssnData.metaData?.workedExample
  )

  const [isHiddenExampleCache, setIsHiddenExampleCache] = useState(
    props.serverAssnData.metaData?.type == 'graphics' || props.serverAssnData.metaData?.isRandom
  )

  const [isRandom, setIsRandom] = useState(
    props.serverAssnData?.metaData?.isRandom
      ? props.serverAssnData?.metaData?.isRandom
      : false
  )

  const childProps = {
    ...props,
    setWorkedExampleCache,
    setIsHiddenExampleCache
  }

  return (
    <>
      <h2>Assignment Editor
        <button
          className="btn btn-light"
          onClick={() => navigate(assnPath)}
        >
          <FaPlay />
        </button>
      </h2>
      <AssnEditorMeta {...childProps} />
      <hr />
      <AssnEditorPrompt {...childProps} />
      <hr />
      <EditStarterCode {...childProps} />

      {workedExampleCache && (
        <>
          <hr />
          <AssnEditorSolution {...childProps} />
        </>
      )}
      <hr />
      <UploadFile {...childProps} />
      <hr />
      {isHiddenExampleCache ?
        <EditHiddenExamples {...childProps} />
        : <EditUnitTests {...childProps} />
      }
      {/** ! @joshdelg edits for regex randomness editor! */}
      <hr />
      {
        isRandom && <EditRandomRegex {...childProps} />
      }
      <div style={{ height: 20 }} />
    </>
  )
}

const EditStarterCode = ({ serverAssnData, courseId, assnId }) => {
  let currCode = serverAssnData?.starterCode

  const [defaultCode, setDefaultCode] = useState(
    currCode ? currCode["main.py"] : ""
  )
  const [code, setCode] = useState(defaultCode)
  const [dirtyBit, setDirtyBit] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  function onUpdate(newCode) {
    setCode(newCode)
    if (newCode === defaultCode) {
      setDirtyBit(false)
    } else {
      setDirtyBit(true)
    }
  }

  function saveStarterCode() {
    setIsSaving(true)
    updateStarterCode(courseId, assnId, code, () => {
      setDirtyBit(false)
      setIsSaving(false)
      setDefaultCode(code)
    })
  }

  let buttonTxt = isSaving ? "Saving..." : "Save"
  buttonTxt = dirtyBit ? "Save" : "Saved"
  return (
    <div className="d-flex flex-column mt-3">
      <h3>Starter Code</h3>
      <MonacoTipTap
        mode="python"
        value={code}
        onChange={(e) => onUpdate(e)}
        readOnly={false}
        style={{ width: "100%" }}
      />
      <button
        disabled={!dirtyBit || isSaving}
        onClick={() => saveStarterCode()}
        style={{ width: 100 }}
        className="mt-2 btn btn-primary"
      >
        {buttonTxt}
      </button>
    </div>
  )
}

const AssnEditorPrompt = ({ serverAssnData, courseId, assnId }) => {
  const promptPath = `assns/${courseId}/assignments/${assnId}/docs/prompt`
  return (
    <div className="d-flex flex-column mt-3 w-100">
      <h3>Prompt</h3>
      <TipTap
        editable={true}
        firebaseDocPath={promptPath}
        buttonBar={AssnEditorButtonBar}
      />
    </div>
  )
}

const AssnEditorSolution = ({ serverAssnData, courseId, assnId }) => {
  const promptPath = `assns/${courseId}/assignments/${assnId}/docs/soln`
  return (
    <div className="d-flex flex-column mt-3 w-100">
      <h3>Solution</h3>
      <TipTap
        editable={true}
        firebaseDocPath={promptPath}
        buttonBar={AssnEditorButtonBar}
      />
    </div>
  )
}

const AssnEditorMeta = ({
  serverAssnData,
  courseId,
  assnId,
  setWorkedExampleCache,
  setIsHiddenExampleCache,
}) => {
  const assnType = serverAssnData.metaData.type
  const [title, setTitle] = useState(serverAssnData.metaData.title)
  const [isWorkedExample, setIsWorkedExample] = useState(
    serverAssnData?.metaData?.workedExample
      ? serverAssnData?.metaData?.workedExample
      : false
  )
  const [isRandom, setIsRandom] = useState(
    serverAssnData?.metaData?.isRandom
      ? serverAssnData?.metaData?.isRandom
      : false
  )
  const [isHiddenExample, setIsHiddenExample] = useState(isRandom || assnType == 'graphics')
  const [assnTags, setAssnTags] = useState(serverAssnData.metaData.tags ?? [])
  const [dirtyBit, setDirtyBit] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  // if something has been changed and changed back to original values, reset dirty bit
  useEffect(() => {
    if (
      title === serverAssnData.metaData.title &&
      isWorkedExample == serverAssnData?.metaData?.workedExample &&
      isRandom == serverAssnData?.metaData?.isRandom &&
      assnTags == serverAssnData.metaData.tags
    ) {
      setDirtyBit(false)
    }
  }, [title, isWorkedExample, isRandom, assnTags])

  useEffect(() => {
    setIsHiddenExample(isRandom || assnType == 'graphics')
  }, [isRandom])

  function updateTitle(newTitle) {
    setTitle(newTitle)
    setDirtyBit(true)
  }

  function updateIsWorkedExample(checked) {
    setIsWorkedExample(checked)
    setDirtyBit(true)
  }

  function updateIsRandom(checked) {
    setIsRandom(checked)
    setDirtyBit(true)
  }

  function saveMetaData() {
    setIsSaving(true)
    updateMetaData(
      courseId,
      assnId,
      {
        title: title,
        type: assnType,
        workedExample: isWorkedExample,
        isRandom: isRandom,
        tags: assnTags,
      },
      () => {
        setDirtyBit(false)
        setIsSaving(false)
        setWorkedExampleCache(isWorkedExample)
        setIsHiddenExampleCache(isHiddenExample)
      }
    )
  }

  let buttonTxt = isSaving ? "Saving..." : "Save"
  buttonTxt = dirtyBit ? "Save" : "Saved"

  return (
    <div className="form-group">
      <h3>Meta Data</h3>

      <b>Assignment Title:</b>
      <input
        type="text"
        className="form-control"
        placeholder="Enter title"
        value={title}
        onChange={(e) => {
          updateTitle(e.target.value)
        }}
      />

      <p className="mt-2"><b>Assignment Type:</b> {assnType} </p>

      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          checked={isWorkedExample}
          id="flexCheckDefault"
          onChange={(e) => updateIsWorkedExample(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          Is this a worked example?
        </label>
      </div>
      {assnType != 'karel' &&
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={isRandom}
            id="flexCheckDefault"
            onChange={(e) => updateIsRandom(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Does this contain randomness?
          </label>
        </div>
      }

      <b className="mt-2">Assignment Tags:</b>
      <TagEditor
        assnTags={assnTags}
        setAssnTags={setAssnTags}
        setDirtyBit={setDirtyBit}
      />

      <button
        disabled={!dirtyBit || isSaving}
        onClick={() => saveMetaData()}
        style={{ width: 100 }}
        className="mt-2 btn btn-primary"
      >
        {buttonTxt}
      </button>
    </div>
  )
}

const TagEditor = ({ assnTags, setAssnTags, setDirtyBit }) => {
  const [input, setInput] = useState("")

  function addTag() {
    if (input.trim() !== "" && !assnTags.includes(input.trim())) {
      setAssnTags([...assnTags, input.trim()])
      setInput("")
    }
    setDirtyBit(true)
  }

  function removeTag(removedTag) {
    setAssnTags(assnTags.filter((tag) => tag !== removedTag))
    setDirtyBit(true)
  }

  return (
    <div>
      {assnTags.map((tag, index) => (
        <Badge pill className="mr-2" bg="secondary" key={index}>
          {tag}
          <CloseButton
            onClick={() => removeTag(tag)}
            variant="white"
            style={{ marginLeft: "2px", height: "10px" }}
          />
        </Badge>
      ))}
      <InputGroup className="mt-3">
        <FormControl
          placeholder="New tag"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(event) => {
            if (event.key === "Enter") addTag()
          }}
        />
        <Button variant="outline-secondary" onClick={addTag}>
          Add
        </Button>
      </InputGroup>
    </div>
  )
}

// if an assnId doesn't exist, create it!
export async function createNewAssignment(
  courseId,
  assnId,
  assnTitle,
  assnType,
  onCreate
) {
  const metaData = {
    title: assnTitle,
    type: assnType,
  }
  updateMetaData(courseId, assnId, metaData, onCreate)
}

async function updateMetaData(courseId, assnId, metaData, callbackFn) {
  const db = getFirestore()
  const path = `assns/${courseId}/assignments/${assnId}`
  const assnRef = doc(db, path)
  await setDoc(assnRef, metaData, { merge: true })
  callbackFn({ metaData })
}

export async function deleteAssignment(courseId, assnId, callback = () => { }) {
  const db = getFirestore()
  const path = `assns/${courseId}/assignments/${assnId}`
  const assnRef = doc(db, path)
  await deleteDoc(assnRef)
  callback()
}

async function updateStarterCode(courseId, assnId, starterCode, callbackFn) {
  const db = getFirestore()
  const assnRef = doc(
    db,
    `assns/${courseId}/assignments/${assnId}/docs/starterCode`
  )
  await setDoc(assnRef, { "main.py": starterCode }, { merge: true })
  callbackFn()
}

const UploadFile = ({ serverAssnData, courseId, assnId }) => {
  const [fileName, setFileName] = useState("")
  const [fileContents, setFileContents] = useState("")
  const [dirtyBit, setDirtyBit] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [recentlyUploadedFile, setRecentlyUploadedFile] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [currCode, setCurrCode] = useState(
    serverAssnData?.starterCode ? serverAssnData.starterCode : {}
  )

  const [openFileSelector, { plainFiles, loading }] = useFilePicker({
    accept: ["image/*", ".txt", ".json", ".py", ".csv", ".w"],
    multiple: false,
    readFilesContent: false,
  })

  const handleFileUpload = (event) => {
    event.stopPropagation()

    if (!isUploading) {
      openFileSelector()
    }
  }

  function saveFile() {
    setIsSaving(true)
    uploadCustomFile(courseId, assnId, fileName, fileContents, () => {
      currCode[fileName] = fileContents
      setDirtyBit(false)
      setIsSaving(false)
      setIsUploading(false)
    })
  }

  useEffect(() => {
    if (recentlyUploadedFile) {
      if (
        recentlyUploadedFile.type === "text/plain" ||
        recentlyUploadedFile.type === "text/x-python-script" ||
        recentlyUploadedFile.type === "application/json" ||
        recentlyUploadedFile.name.substring(
          recentlyUploadedFile.name.length - 2
        ) === ".w" ||
        recentlyUploadedFile.type === "text/csv"
      ) {
        setFileContents(recentlyUploadedFile.content)
        setFileName(recentlyUploadedFile.name)
      }
    }
  }, [recentlyUploadedFile])

  useEffect(() => {
    if (plainFiles.length > 0 && !isUploading) {
      setDirtyBit(true)

      const firstFile = plainFiles[0]
      setIsUploading(true)
      if (
        firstFile.type === "text/plain" ||
        firstFile.type === "text/x-python-script" ||
        firstFile.type === "application/json" ||
        firstFile.name.substring(firstFile.name.length - 2) === ".w" ||
        firstFile.type === "text/csv"
      ) {
        const reader = new FileReader()
        reader.onloadend = () => {
          const content = reader.result
          setRecentlyUploadedFile({
            name: firstFile.name,
            content: content,
            type: firstFile.type,
          })
        }
        reader.readAsText(firstFile)
      }
    }
  }, [plainFiles])

  let buttonTxt = isSaving ? "Saving..." : "Save"
  buttonTxt = dirtyBit ? "Save" : "Saved"

  const removeFile = (filename) => {
    removeFileFromDB(courseId, assnId, filename, () => {
      setCurrCode((prevCode) => {
        const newPrevCode = { ...prevCode }
        delete newPrevCode[filename]
        return newPrevCode
      })
    })
  }

  return (
    <div>
      <h3>Upload File</h3>
      <p>
        Note: After uploading file and revisiting project, you have to hit the
        "restart" button.
      </p>
      <p>Currently accepts .txt, .json, and .py files. </p>

      <h4>Current Files</h4>
      {!currCode ? (
        <p>No files uploaded</p>
      ) : (
        <>
          {Object.keys(currCode).map((name) => {
            return (
              <div
                className="d-flex justify-content-left align-items-baseline"
                id={name}
              >
                <p>{name}</p>
                <button
                  className="btn btn-danger m-1"
                  onClick={() => removeFile(name)}
                >
                  X
                </button>
              </div>
            )
          })}
        </>
      )}
      <p>Recently Uploaded: {fileName}</p>
      <button className="btn btn-secondary" onClick={handleFileUpload}>
        <FaUpload />
      </button>
      <button
        disabled={!dirtyBit || isSaving}
        onClick={() => saveFile()}
        style={{ width: 100 }}
        className="m-1 btn btn-primary"
      >
        {buttonTxt}
      </button>
    </div>
  )
}

async function uploadCustomFile(
  courseId,
  assnId,
  fileName,
  fileContents,
  callbackFn
) {
  const db = getFirestore()
  const assnRef = doc(
    db,
    `assns/${courseId}/assignments/${assnId}/docs/starterCode`
  )
  await setDoc(assnRef, { [fileName]: fileContents }, { merge: true })
  callbackFn()
}

async function removeFileFromDB(courseId, assnId, fileName, callbackFn) {
  const db = getFirestore()
  const assnRef = doc(
    db,
    `assns/${courseId}/assignments/${assnId}/docs/starterCode`
  )
  await setDoc(assnRef, { [fileName]: deleteField() }, { merge: true })
  callbackFn()
}