import { addDoc, collection, doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection, useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { fetchAllAssignmentMetaData, fetchAssignments } from "../../../utils/general";
import { getAuth } from "firebase/auth";
import { useCourseId } from "hooks/router/useUrlParams";
import { ProfileContext } from "contexts/ProfileContext";
import { isStaffRole } from "contexts/ProfileUtil";
import Swal from "sweetalert2";
import { useUserId } from "hooks/user/useUserId";
import { getApp } from "firebase/app";

/**
 * Assignments are reactive to the firebase, so if you change
 * the value there, everyones assignment list will update!
 */

const defaultData = {
  onboardingData:{},
  onboardingLoading:true,
  editOnboarding:(v)=>{},
};

export const SLOnboardingContext = React.createContext(defaultData);

export const SLOnboardingProvider = ({children }) => {
  
  const userId = useUserId()
  const courseId = useCourseId()
  // the user already has logic for loading their section data!
  // you are garunteed these are loaded from the server at the start
  const {sectionData, userData, setSectionData, setCachedUserData} = useContext(ProfileContext)

  const onboardingDocPath = `users/${userId}/${courseId}/slonboarding`
  const db = getFirestore(getApp())
  const onboardingDocRef = doc(db, onboardingDocPath)
  const [onboardingData, onboardingLoading, onboardingError] = useDocumentData(onboardingDocRef)
  

  useEffect(()=> {
    console.log('new user data',userData.courseSections.length)
    const nSections = userData.courseSections.length

    if (nSections == 0) {
      // this will eventually change sectionData
      createNewSection(courseId, userId, userData,setSectionData, setCachedUserData)
    } 
    
  },[userData])

 
  const editOnboarding = async (newData) => {
    console.log(newData)
    console.log(onboardingDocPath)
    await setDoc(doc(db, onboardingDocPath), newData, {merge:true})
  }

  const nSections = userData.courseSections.length || 0
  const isLoading = onboardingLoading || nSections == 0

  return (
    <SLOnboardingContext.Provider
      value={{
        onboardingData:onboardingData || {},
        onboardingLoading:isLoading,
        editOnboarding:editOnboarding
      }}
    >
      {children}
    </SLOnboardingContext.Provider>
  );
};

const createNewSection = async (courseId, userId, userData,setSectionData,setCachedUserData) => {
  console.log('Creating new section')
  const db = getFirestore(getApp())
  // 1. create a new document in the /sections collectinos
  // 2. add the user to the section
  console.log(userData)
  const displayName = userData?.displayName || 'Anonymous'
  // display name may be just one name, or it might be two
  const firstName = displayName.split(' ')[0] || 'Section'
  // the "Section" tag is added on the section page
  const newSectionName = `${firstName}` 
  const newSectionData = {
    courseId,
    sectionLeader:userId,
    isActive:false, // not active until it is set so by the admin
    name:newSectionName,
    isMinor:userData?.isMinor,
    timeIndex:'none'
  }
  const newSectionDocRef = await addDoc(collection(db, 'sections'), newSectionData)

  // const sectionId = 'abcdef'

  const sectionId = newSectionDocRef.id

  // update my section pointer
  const myDocPath = `users/${userId}`
  const myDocRef = doc(db, myDocPath)
  const sectionRef = doc(db,`sections/${sectionId}`)
  const newSectionList = []
  newSectionList.push(sectionRef)
  
  const updatedUser = {
    sections:{
      [courseId]:newSectionList
    }
  }

  
  await setDoc(myDocRef, updatedUser, {merge:true})

  // this is data for a particular section
  await setSectionData((prev) => {
    return {
      ...prev,
      [sectionId]: newSectionData,
    };
  });

  // this is the list of all sections
  // WARNING: note how "courseSections" is different from "sections"?
  // cachedUser uses "courseSections"
  await setCachedUserData((prev) => {
    return {...prev, courseSections:[{
      path:'sections/'+sectionId,
    }]}
  })
  console.log('done')
}