import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { FaEdit } from "react-icons/fa"
import { EditRowForm } from "./EditRowForm"

export const EditRowButton = (props) => {
    const [showEditRowForm, setShowEditRowForm] = useState(false)
    const handleClose = () => setShowEditRowForm(false);
    const handleShow = () => setShowEditRowForm(true);

    return <>
        <Button variant="light" onClick={handleShow}><FaEdit/></Button>
        <EditRowForm showEditRowForm={showEditRowForm} handleClose={handleClose} {...props}/>
    </>
}
