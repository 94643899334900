import React, { useState, useContext, useEffect } from "react";
import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer";
import { useCourseId } from "../../hooks/router/useUrlParams";
import { ProfileContext, Role } from "contexts/ProfileContext";
import { DiscussionButtonBar } from "components/richTextEditor/TipTap/buttonbars/DiscussionButtonBar";
import { isMinimumRole } from "contexts/ProfileUtil";
import { TipTap } from "components/richTextEditor/TipTap/TipTap";
import { LinkContainer } from "react-router-bootstrap";
import { FaEdit } from "react-icons/fa";
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { StudentSplashMain } from "./NewStudentHome";
import Gate from "contexts/Gate"
import styled from "styled-components";
import { EnrollmentContext } from "contexts/EnrollmentContext";
import { AdminNudgeButton } from "course/carehours/AdminNudgeButton";
import { useDocumentData, useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import Swal from "sweetalert2";
import { CourseContext } from "contexts/CourseContext";


export const AdminHome = () => {
  const { userData } = useContext(ProfileContext)
  const isAdmin = Gate.hasAdminRole(userData)
  if (!isAdmin) {
    return <></>
  }
  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="admin">
      <CoursePageBodyContainer
        mainColumn={<AdminSplashMain />}
        rightColumn={<AdminRightCol />}
        singleColumn={<AdminSplashMain />}
      />
    </Tab.Container>
  );
}



const AdminSplashMain = () => {
  const courseId = useCourseId();

  return <>
    {/* <CourseTitle subtitle={"Course Admin"} /> */}

    {/* <AdminAnnouncement courseId={courseId}/> */}

    <AdminTools courseId={courseId} />
  </>
}



const AdminTools = ({ courseId }) => {
  return <>
    <Editors courseId={courseId} />
    <Monitoring courseId={courseId} />
    <Administration courseId={courseId} />
    <Grading courseId={courseId} />
    <ReleaseButtons courseId={courseId} /> 
  </>
}

const Editors = ({ courseId }) => {
  return <>
    <div className="splashCardRightTitle mt-4" >Editors</div>
    <Container>
      <Row className='mt-2'>
        <Col>
          <AdminButton to={`/${courseId}/roadmapeditor`} className='w-100'>
            <Button variant='outline-primary' className='w-100'>Roadmap Editor</Button>
          </AdminButton>
        </Col>
        <Col>
          <AdminButton to={`/${courseId}/lessoneditor/student`} className='w-100'>
            <Button variant='outline-primary' className='w-100'>Lesson Editor</Button>
          </AdminButton>
        </Col>
        <Col>
          <AdminButton to={`/${courseId}/assneditor`} className='w-100'>
            <Button variant='outline-primary' className='w-100'>
              {/* <FaPython size={24} className="mr-1"/>  */}
              Assignment Editor</Button>
          </AdminButton>
        </Col>
        <Col>
          <AdminButton to={`/${courseId}/eventseditor`} className='w-100'>
            <Button variant='outline-primary' className='w-100'>Events Editor</Button>
          </AdminButton>
        </Col>
        <Col>
          <AdminButton to={`/${courseId}/docseditor`} className='w-100'>
            <Button variant='outline-primary' className='w-100'>Docs Editor</Button>
          </AdminButton>
        </Col>
        <Col>
          <AdminButton to={`/${courseId}/lessoneditor/teacher`} className='w-100'>
            <Button variant='outline-primary' className='w-100'>Training Editor</Button>
          </AdminButton>
        </Col>
      </Row>
    </Container>
  </>
}

const Monitoring = ({ courseId }) => {
  const { usesTeachNow} = useContext(CourseContext)


  return <>
    <div className="splashCardRightTitle mt-4" >Monitoring</div>
    <Container>
      <Row className='mt-2'>
        <StudentOverviewButton courseId={courseId} />
        <Col>
          <AdminButton to={`/${courseId}/monitorsections`} className='w-100'>
            <Button variant='outline-primary' className='w-100'>Monitor Sections</Button>
          </AdminButton>
        </Col>
        <Col>
          <AdminButton to={`/${courseId}/slattendance`} className='w-100'>
            <Button variant='outline-primary' className='w-100'>SL Attendance</Button>
          </AdminButton>
        </Col>
        <MonitorApps targetCourseId={'cip4'} />
        { usesTeachNow && <Col>
          <AdminButton to={`/${courseId}/teachnowadmin`} className='w-100'>
            <Button variant='outline-primary' className='w-100'>TeachNow</Button>
          </AdminButton>
        </Col>}
      </Row>
      <div className="row mt-3">
        <div className="col">
          <AdminButton to={`/${courseId}/forumstats`} className='w-100'>
            <Button variant='outline-primary' className='w-100'>Forum Stats</Button>
          </AdminButton>
        </div>
        <div className="col">
          <AdminButton to={`/${courseId}/sharedprojects`} className='w-100'>
            <Button variant='outline-primary' className='w-100'>Shared Projects</Button>
          </AdminButton>
        </div>
        <div className="col">
          <AdminButton to={`/${courseId}/overallcompletions`} className='w-100'>
            <Button variant='outline-primary' className='w-100'>Overall Completions</Button>
          </AdminButton>
        </div>
    </div>
  </Container >
  </>
}

const Administration = ({ courseId }) => {
  return <>
    <div className="splashCardRightTitle mt-4" >Administrative Tools</div>
    <Container>
      <Row className='mt-2'>
        <Col>
          <AdminButton to={`/${courseId}/setpermissions`} className='w-100'>
            <Button variant='outline-primary' className='w-100'>Set Permissions</Button>
          </AdminButton>
        </Col>
        <Col>
          <AdminButton to={`/${courseId}/admin`} className='w-100'>
            <Button variant='outline-primary' className='w-100'>Course Features</Button>
          </AdminButton>
        </Col>
      </Row>
    </Container>
  </>
}

const Grading = ({ courseId }) => {
  // TODO @miranda: unhardcode this
  const isFoothill = courseId === "foothill-cs49"

  if (!isFoothill) return <></>

  return <>
    <div className="splashCardRightTitle mt-4" >Grading</div>
    <Container>
      <Row className='mt-2'>
        <Col>
          <AdminButton to={`/${courseId}/gradingsplash`} className='w-100'>
            <Button variant='outline-primary' className='w-100'>Grade assignments</Button>
          </AdminButton>
        </Col>
      </Row>
    </Container>
  </>
}

const StudentOverviewButton = ({ courseId }) => {
  // TODO @miranda: unhardcode this
  const isFoothill = courseId === "foothill-cs49" || courseId === "devtest"

  if (!isFoothill) return <></>

  return <Col>
    <AdminButton to={`/${courseId}/studentoverview`} className='w-100'>
      <Button variant='outline-primary' className='w-100'>Student Overview</Button>
    </AdminButton>
  </Col>
}


const MonitorApps = ({ targetCourseId }) => {
  const { roles } = useContext(EnrollmentContext)
  const courseId = useCourseId()
  const roleEnum = roles[targetCourseId]
  const isAdmin = roleEnum >= Role.ADMIN
  if (!isAdmin) return <></>
  return <Col>
    <AdminButton to={`/${courseId}/monitorapps/${targetCourseId}`} className='w-100'>
      <Button variant='outline-primary' className='w-100'>Monitor Apps</Button>
    </AdminButton>
  </Col>
}

const AdminRightCol = () => {
  return <>
  <AdminNudgeButton />
  </>
}



const ReleaseButtons = ({ courseId }) => {
  const db = getFirestore()
  const [courseReleaseData, courseReleaseLoading, courseReleaseError] = useDocumentData(doc(db,`course/${courseId}/docs/releases`))

  const setKeyReleased = async (key: string, value: boolean) => {
    await setDoc(
      doc(db, `course/${courseId}/docs/releases`), 
    {[key]: value}, 
    {merge: true})
  }

  const setCertificateReleased = async (value: boolean) => {  
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `Setting this to ${value} will affect the release of the certificate to all students.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    })
    if (result.isConfirmed) {
      await setKeyReleased('certificate', value)
    }

  }

  useEffect(() => {
    console.log(courseReleaseData)
  }, [courseReleaseData])


  if (courseReleaseLoading) return <></>


  return <>
    <div className="splashCardRightTitle mt-4" >Release</div>
    <Container>
      <Row className='mt-2'>
        <Col>
        <p>Is Certificate Released: <span className="text-primary">{courseReleaseData?.certificate ? 'Yes' : 'No'}</span></p>
        {/* add switch */}
        <button className="btn btn-sm btn-primary" onClick={() => setCertificateReleased(!courseReleaseData?.certificate)}>{courseReleaseData?.certificate ? "Unrelease ": "Release " } Certificate</button>

        </Col>
      </Row>
    </Container>
  </>

}





const AdminButton = styled(LinkContainer)`
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:1.1rem;
`
