import { useState, useEffect, useContext } from "react";
import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer";
import { PartialLoading } from "components/loading/Loading";
import { ProfileContext } from "contexts/ProfileContext";
import { CourseContext } from "contexts/CourseContext";
import { getFirestore, doc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useCourseId } from "hooks/router/useUrlParams"
import Gate from "contexts/Gate";
import { getLocalUTCTimezone } from "components/timezones/timezoneHelper";
import { OverviewTable } from "course/section/components/OverviewTable";
import { Spacer } from "components/layout/Spacer";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
const adminGetAllStudentGrades = httpsCallable(functions, 'adminGetAllStudentGrades');

export const StudentOverview = () => {
    return (
        <CoursePageBodyContainer
            mainColumn={<StudentOverviewTable />}
            singleColumn={<StudentOverviewTable />}
            rightColumn={<></>}
        />
    )

}

const StudentOverviewTable = () => {
    const courseId = useCourseId()
    const db = getFirestore()
    const studentRosterRef = doc(db, `course/${courseId}/rosters/student`);
    const [studentRosterData, studentRosterLoading, studentRosterError] = useDocumentData(studentRosterRef)
    const { sectionTimeDelta, nCourseWeeks, firstSectionTimestamp, courseCanvasAuthLink, getNextSectionIndex } = useContext(CourseContext)

    const { userData } = useContext(ProfileContext)
    const isAdmin = Gate.hasAdminRole(userData)

    const [numSections, setNumSections] = useState(-1)
    const [membersGrades, setMembersGrades] = useState({})
    const userTz = getLocalUTCTimezone()

    useEffect(() => {
        if (!firstSectionTimestamp) return;
        let nextSectionIdx= getNextSectionIndex(userTz, sectionTimeDelta) // if we don't know section time assume latest possible
        setNumSections(nextSectionIdx + 1 < nCourseWeeks ? nextSectionIdx + 1 : nCourseWeeks)

    }, [firstSectionTimestamp]);

    useEffect(() => {
        if (!studentRosterData || studentRosterLoading) return;
        console.log(studentRosterData)
        const membersList = Object.keys(studentRosterData)

        const fetchMembersGrades = async () => {

            try{
                // fetch all student grades
                const response = await adminGetAllStudentGrades({
                    adminId: userData.id,
                    courseId: courseId
                })
                console.log("heeee")
                console.log(response)
                if(!response || !response.data) {
                    // handle error
                    console.log("Error fetching grades")
                    return
                }
                // membersGrades is a map of userId to grades object
                const membersGrades = Object.fromEntries(response.data.map(
                    enrollmentData => {
                        return [enrollmentData.firestoreUserId, enrollmentData.grades]
                    }
                )
                )
                setMembersGrades(membersGrades)
            } catch(error){
                console.error('Error fetching member grades: ', error);
            }
        };

        if (courseCanvasAuthLink !== ""){
            // TODO @miranda: using course canvas auth link to tell if course has grades
            fetchMembersGrades();
        }
    }, [studentRosterData])

    // admin only, FOOTHILL + devtest ONLY!!! @TODO miranda
    if (!isAdmin || !(courseId === "foothill-cs49" || courseId === "devtest")) {
        return <></>
    }

    if (studentRosterLoading || studentRosterError) {
        return <PartialLoading />
    }

    return <>
    <Spacer />
    <h3>Student Overview</h3>
    <OverviewTable
        membersList={Object.keys(studentRosterData)}
        numSections={numSections}
        membersGrades={membersGrades}
    />
    </>
}