import { useState, useEffect } from "react";
import PeerHighlightQuestions from "./components/PeerHighlightQuestions";
import StudentFeedbackHeader from "./components/StudentFeedbackHeader";
import StudentEngagement from "./components/StudentEngagement";
import StudentReflection from "./components/StudentReflection";
import styled from "styled-components";
import MessageBoard from "./components/MessageBoard";
import { useUserId } from "hooks/user/useUserId";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { getAuth } from "firebase/auth";
import Swal from "sweetalert2";
import { Loading } from "components/loading/Loading";
import { useCourseId } from "hooks/router/useUrlParams";
import { useContext } from "react";
import { ProfileContext, Role } from "contexts/ProfileContext";

const SectionContainer = styled.div`
  margin-bottom: 50px;
`;

export function submitDataToFirestore(
  docRef,
  data,
  alertMessage,
  isAlert = true
) {
  setDoc(docRef, data, { merge: true })
    .then(() => {
      if (!isAlert) return;
      Swal.fire({
        icon: "success",
        title: alertMessage,
      });
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
    });
}

function getPreviousWeek(currentWeek) {
  switch (currentWeek) {
    case "week1":
      return "week0";
    case "week2":
      return "week1";
    case "week3":
      return "week2";
    case "week4":
      return "week3";
    case "week5":
      return "week4";
    case "week6":
      return "week5";
    default:
      return null;
  }
}

function getTalktimeDifference(talkTime, previousTalkTime) {
  if (!talkTime || !previousTalkTime) {
    return null;
  }

  return Math.abs(talkTime - previousTalkTime);
}

function checkIfDifferenceIsPositive(talkTime, previousTalkTime) {
  if (!talkTime || !previousTalkTime) {
    return null;
  }

  return talkTime > previousTalkTime;
}

const StudentFeedbackComponents = ({ week, sectionId, group }) => {
  const db = getFirestore();
  const auth = getAuth();
  const { userData } = useContext(ProfileContext);
  const userId = userData.id;
  const courseId = useCourseId();
  const previousWeek = getPreviousWeek(week);
  const feedbackDataPath = `/transcriptFeedback/${courseId}/${week}/feedback/${sectionId}/data`;
  const feedbackDataDocRef = doc(db, `${feedbackDataPath}`);
  const [feedbackData, feedbackLoading] = useDocumentData(feedbackDataDocRef);

  const previousFeedbackDataPath = `/transcriptFeedback/${courseId}/${previousWeek}/feedback/${sectionId}/data`;
  const previousFeedbackDataDocRef = doc(db, `${previousFeedbackDataPath}`);
  const [previousFeedbackData, previousFeedbackLoading] = useDocumentData(
    previousFeedbackDataDocRef
  );

  if (feedbackLoading || previousFeedbackLoading) {
    return <Loading />;
  }

  if (feedbackData === undefined) {
    return (
      <div>
        <MessageBoard message={"Feedback not available."} />
      </div>
    );
  }

  return (
    <div className="mt-4 mb-5">
      <SectionContainer>
        <StudentFeedbackHeader
          studentName={userData.displayName}
          courseWeek={feedbackData.weekName}
        />
      </SectionContainer>

      {feedbackData?.wholeSectionHighlightQuestions && (
        <SectionContainer>
          <PeerHighlightQuestions
            submittedQuestion={
              feedbackData.studentQuestions?.[userId]?.["question"] || ""
            }
            // Change this line in order to connect to firebase
            onQuestionSubmit={(question) => {
              submitDataToFirestore(
                feedbackDataDocRef,
                {
                  studentQuestions: {
                    [userId]: {
                      studentName: userData.displayName,
                      question: question,
                    },
                  },
                },
                "Question Submitted!"
              );
            }}
            questionsAsked={
              feedbackData.wholeSectionHighlightQuestions.questionsAsked
            }
            numberOfQuestionsAskedByClass={
              feedbackData.wholeSectionHighlightQuestions
                .numberOfQuestionsAskedByClass
            }
          />
        </SectionContainer>
      )}
      {feedbackData?.studentEngagement && (
        <SectionContainer>
          <StudentEngagement
            sectionAverageValue={feedbackData.studentEngagement.cipWeekAverage}
            userValue={feedbackData.studentEngagement.sectionAverage}
            goalValue={feedbackData.studentEngagement.goal}
            lastWeekDifference={getTalktimeDifference(
              feedbackData.studentEngagement.sectionAverage,
              previousFeedbackData?.studentEngagement?.sectionAverage
            )}
            isLastWeekDifferencePositive={checkIfDifferenceIsPositive(
              feedbackData.studentEngagement.sectionAverage,
              previousFeedbackData?.studentEngagement?.sectionAverage
            )}
          />
        </SectionContainer>
      )}
      <SectionContainer>
        <StudentReflection
          hideFeedbackSection={group == "StudentFeedback"}
          submittedLesson={
            feedbackData.studentReflection?.[userId]?.["lessonLearnt"] || ""
          }
          submittedFeedback={
            feedbackData.studentFeedback?.[userId]?.["feedback"] || ""
          }
          onLessonSubmit={(lessonLearnt) => {
            submitDataToFirestore(
              feedbackDataDocRef,
              {
                studentReflection: {
                  [userId]: {
                    studentName: userData.displayName,
                    lessonLearnt: lessonLearnt,
                  },
                },
              },
              "Submitted!"
            );
          }}
          onFeedbackSubmit={(feedback) => {
            submitDataToFirestore(
              feedbackDataDocRef,
              {
                studentFeedback: {
                  [userId]: {
                    studentName: userData.displayName,
                    feedback: feedback,
                  },
                },
              },
              "Feedback Submitted!"
            );
          }}
        />
      </SectionContainer>

      <div>
        <p>Keep up the great work, and stay tuned for next week! 👋</p>
      </div>
    </div>
  );
};

export default StudentFeedbackComponents;
