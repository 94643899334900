import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer"
import { CompletionContext } from "contexts/CompletionContext"
import { ProfileContext } from "contexts/ProfileContext"
import { isAdmin } from "contexts/ProfileUtil"
import { TeachNowButtonWrapper } from "course/carehours/CareHoursPage"
import { AdminRichTextEditor } from "course/components/AdminRichTextEditor"
import { useCourseId } from "hooks/router/useUrlParams"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import NoAccess from "components/errors/NoAccess"
import { RoadmapContext } from "course/contexts/RoadmapContext"
import { TimeContext } from "contexts/TimeContext"
import { useCompletionMap } from "hooks/user/useCompletion"
import { ROADMAP } from "course/editors/roadmapEditor/RoadmapSchema"
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import { FaHandshake } from "react-icons/fa"
import { getIsTeachNowStudent, setStudentJoinStatus, useStudentJoinStatus } from "./TNFirebase"
import { useUserId } from "hooks/user/useUserId"
import { Loading } from "components/loading/Loading"
import { TeachNowImpactButtonLink } from "course/splash/NewTeachingHome"


export const StudentTeachNow = () => {

    return (
      <CoursePageBodyContainer
        mainColumn={<StudentTeachNowSplash />}
        rightColumn={<TeachNowImpactButtonLink/>}
        singleColumn={<StudentTeachNowSplash />}
      />
    )
}


const JoinStudentTeachNow = ({ userId, courseId }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function agreeAndJoin() {
    setStudentJoinStatus(courseId, userId, true)
    setShow(false)
  }

  return (
    <>
      <Button variant="primary" className="mt-2 btn-lg" onClick={handleShow} >
        Join
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Join as a Section Leader in Training</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Teaching others is a privilege and a significant responsibility. By joining as a Peer Helper in this course, you must agree to the following terms and conditions:
          </p>
          <ul>
            <li>I commit to being welcoming, inclusive, and to fostering an environment of learning without directly giving away answers.</li>
            <li>I understand that all sessions are conducted on Zoom and will be recorded.</li>
            <li>Course staff may review these recordings to ensure the safety and effectiveness of the teaching environment.</li>
            <li>I agree to be respectful and kind in my role as a Peer Helper.</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          {/* center the buttons */}
          <div style={{display: 'flex', justifyContent: 'center', width: '100%', gap: '5px'}}>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={agreeAndJoin}>
            Agree & Join
          </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const StudentTeachNowButtonWrapper = ({ buttonOnly, teachNowButtonEnabled, isTeachNowStudent, roadmapItemMap}) => {
  const weeklyAssnsCompleted = checkIfFinishedWeeklyAssns(roadmapItemMap)

  if (!teachNowButtonEnabled) {
    return <OverlayTrigger
        placement="top"
        delay={{ show: 100, hide: 100 }}
        overlay={(props) => (
          <Tooltip id="button-tooltip" {...props}>
            Make sure to read through the Learn about TeachNow handout before you can help students.
          </Tooltip>
        )}
      >
        <div>
        <button
            className="btn btn-success mt-1"
            style={{ width: '100%', display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center' }}
            disabled={true}
          >
            <div>
              <FaHandshake size={50} />
            </div>
            Teach Now
          </button>
        </div>
      </OverlayTrigger>
  }
  else if (weeklyAssnsCompleted) {
    return <TeachNowButtonWrapper buttonOnly={buttonOnly} isTeachNowStudent={isTeachNowStudent}/>
  } else {
    return <OverlayTrigger
        placement="top"
        delay={{ show: 100, hide: 100 }}
        overlay={(props) => (
          <Tooltip id="button-tooltip" {...props}>
            You must complete all assignments for the current week before you can help students.
          </Tooltip>
        )}
      >
        <div>
        <button
            className="btn btn-success mt-1"
            style={{ width: '100%', display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center' }}
            disabled={true}
          >
            <div>
              <FaHandshake size={50} />
            </div>
            Teach Now
          </button>
        </div>
      </OverlayTrigger>
  }
}

function checkIfFinishedWeeklyAssns(roadmapItemMap) {
  const { getServerTimeMs } = useContext(TimeContext)
  // loop through all the items in the roadmap and sort by startDate
  const sortedIds = Object.keys(roadmapItemMap).sort((a, b) => roadmapItemMap[a].startDate - roadmapItemMap[b].startDate);
  
  // get the current time right now and convert it to pacific time 
  const currentTime = getServerTimeMs()
  const pacificTime = new Date(currentTime).toLocaleString("en-US", {timeZone: "America/Los_Angeles"})
  
  // then get the key of the most recent item that is before the current time right now 
  const mostRecentModuleId = sortedIds.pop()

  console.log("MOST RECENT MODULE ID", mostRecentModuleId)

  if (!mostRecentModuleId) {
    return false
  }
  const allToDos = roadmapItemMap[mostRecentModuleId]["items"].filter((item) => !item.isOptional)

  console.log("ALL TODOS", allToDos)
  const assns = allToDos.filter((item) => item.itemType === ROADMAP.ASSIGNMENT.key)

  // now check if all the assignments have been completed
  const { completionMap } = useCompletionMap(assns)
  const allAssnsCompleted = assns.every((assn) => completionMap[assn.id])

  console.log("ASSNS", assns)
  console.log("COMPLETION MAP", completionMap)
  console.log("ALL ASSNS COMPLETED", allAssnsCompleted)

  return allAssnsCompleted
}


const StudentTeachNowSplash = () => {
  const { userData } = useContext(ProfileContext)
  const { setTeachNowTrainingSlideCompleted, isReadAboutTeachNowCompleted } = useContext(CompletionContext)
  const { roadmapItemMap } = useContext(RoadmapContext);
  const displayName = userData?.displayName
  const courseId = useCourseId()
  const userId = useUserId()

  const [teachNowButtonEnabled, setTeachNowButtonEnabled] = useState(false)
  const [isTeachNowStudent, setIsTeachNowStudent] = useState(null)

  const isAdminRole = isAdmin(userData.courseRole);

  const styleRetButton = {borderRadius: 5, padding: "10px", textDecoration: "none"}

  const [joinStatus, loading, error] = useStudentJoinStatus(courseId, userId)

  useEffect(() => {
    const checkReadAboutTeachNow = async () => {
      const enabled = await isReadAboutTeachNowCompleted()
      setTeachNowButtonEnabled(enabled)
    }
    checkReadAboutTeachNow()
  }, [])

  useEffect(() => {
    const checkExpGroup = async () => {
      const isInExpGroup = await getIsTeachNowStudent(courseId, userId)
      setIsTeachNowStudent(isInExpGroup)
    }
    checkExpGroup()
  }, [])



  const onLearnAboutTeachNowClick = async () => {
    await setTeachNowTrainingSlideCompleted("readAboutTeachNow")
    window.open(`/${courseId}/teachnow`, "_blank")
  }

  if (loading || isTeachNowStudent === null || !roadmapItemMap) {
    return <Loading />
  }

  // Experiment flag is in users/courseId/experimentFlags and it is studentTeachNow. You can be in this group or you can be an admin to see this page
  if(!isTeachNowStudent && !isAdminRole) {
    return <NoAccess/>
  }

    return <div className="mt-4">
      <img src="/stanford/jacaranda.jpg" alt="Mentorship" className="img-fluid w-100 p-1 rounded" 
        style={{maxHeight: "300px", objectFit: "cover", objectPosition: "bottom center"}}
        />
      <h4>Welcome {displayName ? displayName : ''}!</h4>
      <AdminRichTextEditor firebaseDocPath={`/course/${courseId}/handouts/studentteachnow`}/>

      <div className="d-flex align-items-center justify-content-center" style={{marginBottom: '50px'}}>
      {!joinStatus && <JoinStudentTeachNow courseId={courseId} userId={userId}/>}
      {
        joinStatus && <>
        <button className="btn btn-lg btn-primary m-3" onClick={onLearnAboutTeachNowClick}>Learn About Teach Now!</button>
        <StudentTeachNowButtonWrapper 
          buttonOnly={true} 
          teachNowButtonEnabled={teachNowButtonEnabled} 
          isTeachNowStudent={isTeachNowStudent}
          roadmapItemMap={roadmapItemMap}
        />
        </>
      }
      </div>
    </div>

}