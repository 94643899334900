import {
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { useContext, useState } from "react";
import { ProfileContext, Role } from "contexts/ProfileContext";
import { isActive, getIcon, getTitle } from "./courseSidebarUtils"
import { isExperiencedStudent, roleToFirebaseString } from "contexts/ProfileUtil";
import { CourseContext } from "contexts/CourseContext";

export const GenericSubMenu = ({ courseId, subMenuKey, collapsed, subMenu, iconId, isLinkActive = true, ariaLabel = "dropdown" }) => {

  let someSubPageIsActive = false
  for (const pageName of subMenu) {
    someSubPageIsActive = isActive(pageName)
  }

  const [open, setOpen] = useState(false)
  const handleOpenChange = (newIsOpen) => {
    setOpen(newIsOpen)
  }
  const submenuId = `nav-${subMenuKey}`

  return <SubMenu
    id={submenuId}
    label={
      <>
        {getIcon(iconId)}
        <span>{collapsed ? "" : <>&nbsp;&nbsp;{getTitle(iconId)}</>}</span>
      </>
    }
    aria-label={ariaLabel}
    active={open ? false : someSubPageIsActive} // if submenu is open, highlight the specific active forum (if any) -- if submenu is closed, highlight it if one of the forums (EXCEPT SECTION FORUM, which will show the section tab as active) is active
    open={open}
    defaultOpen={someSubPageIsActive}
    onOpenChange={handleOpenChange}
  >
    {subMenu.map((item, index) => {
      return <MenuItem
        key={`${iconId}-${index}`}
        component={isLinkActive ? <Link to={`/${courseId}/${item}`} /> : null}
        active={isActive(item)}
      >
        <FaChevronRight /> {getTitle(item)}
      </MenuItem>
    })}
  </SubMenu>
}

export const HomeSubMenu = (props) => {
  const subMenu = props.isAdmin ? ['adminhome', 'teachinghome', 'studenthome'] : props.isMentorRole ? ['mentorhome', 'studenthome'] : ['teachinghome', 'studenthome']

  return <GenericSubMenu 
    {...props} 
    subMenu={subMenu} 
    iconId={"home"} 
    subMenuKey="home"
    ariaLabel="home dropdown"
  />
}

const codesPages = [
  "code",
  "practice",
  "create"
]


export const CodeSubMenu = (props) => {
  return <GenericSubMenu
    {...props} 
    subMenu={codesPages} 
    iconId={"code-pages"} 
    ariaLabel="code-dropdown"
    subMenuKey="code"
  />
}


const expStudentForums = [ 
  'forumx'
]

const studentForums = [
  'forum',
  'sectionforum'
]


const sectionLeaderForums = [
  'forum',
  'sectionforum',
  'teacherslounge'
]


export const ForumsSubMenu = ({ courseId, collapsed, isSectionLeader, isLinkActive = true }) => {

  const { userData, userAge } = useContext(ProfileContext)
  const { roadmapList } = useContext(CourseContext)
  const courseRole = userData.courseRole
  const [open, setOpen] = useState(false)


  const sections = userData.sections[courseId]

  // you must be 18 years old to use any of the forums
  if (userAge < 18 && courseId === 'public') {
    return <></>
  }

  // first, construct all the forums that the user is in
  // @TJ todo - this is a super rigid piece of logic to allow experieneced student forum for section leaders
  // Fix later
  let subMenu;
  if (isSectionLeader) {
    if (roadmapList.includes(roleToFirebaseString(Role.EXPERIENCED_STUDENT))) {
      subMenu = [...sectionLeaderForums, 'forumx']
    } else {
      subMenu = sectionLeaderForums
    }
  } else if (isExperiencedStudent(courseRole)) {
    subMenu = expStudentForums
  } else {
    subMenu = studentForums
  }

  // final project forums for those who have them
  const finalProjectCommunities = userData?.finalProjectCommunities
  let projectForumIds;
  if (finalProjectCommunities && courseId in finalProjectCommunities){
    projectForumIds = finalProjectCommunities[courseId]
    const nGroups = projectForumIds ? projectForumIds.length : 0
    if (nGroups >= 1 && !subMenu.includes('projectforum')){
      subMenu.push('projectforum')
    }
}

  const subMenuData = []
  for (const forumName of subMenu) {
    if (!sections && forumName == 'sectionforum') {
      continue
    }
    if(sections && sections.length == 0) {
      continue
    }
    if (forumName == 'sectionforum') {
      // NOTE: we assume users only have one section
      const sectionId = sections[0].id
      subMenuData.push({
        name: forumName,
        link: `/${courseId}/sectionforum/${sectionId}`
      })
    } 
    else if (forumName == 'projectforum'){
      // NOTE: we assume users only have one final project group
      const forumId = projectForumIds[0]
      subMenuData.push({
        name: forumName,
        link: `/${courseId}/projectForum/${forumId}`
      })
    }
    else{
      subMenuData.push({
        name: forumName,
        link: `/${courseId}/${forumName}`
      })
    }
  }

  let someForumIsActive = false
  for (const forumName of subMenu) {
    if (forumName == 'sectionforum') {
      // NOTE: section forum shows section as active in the sidebar
      continue
    }
    someForumIsActive = someForumIsActive || isActive(forumName)
  }

  const handleOpenChange = (newIsOpen) => {
    setOpen(newIsOpen)
  }

  // if there is only one forum, don't render a submenu
  if (subMenuData.length == 1) {
    const forumKey = subMenuData[0].name
    const forumLink = subMenuData[0].link
    return <MenuItem
      id="nav-forum"
      component={isLinkActive ? <Link to={forumLink} /> : null}
      active={isActive(forumKey)}
    >
      {getIcon("forums")}&nbsp;&nbsp;Forum
    </MenuItem>
  }


  return <SubMenu
    label={
      <>
        {getIcon("forums")}
        <span>{collapsed ? "" : <>&nbsp;&nbsp;{getTitle("forums")}</>}</span>
      </>
    }
    active={false} // there was a bug because the open useState starts at false, even if open
    // active={open ? false : someForumIsActive} // if submenu is open, highlight the specific active forum (if any) -- if submenu is closed, highlight it if one of the forums (EXCEPT SECTION FORUM, which will show the section tab as active) is active
    open={open}
    defaultOpen={someForumIsActive}
    onOpenChange={handleOpenChange}
    aria-label="forum dropdown "
    id="nav-forum"
  >
    {subMenuData.map((item, index) => {
      const forumKey = item.name
      const forumLink = item.link
      return <MenuItem

        key={`forum${index}`}
        component={isLinkActive ? <Link to={forumLink} /> : null}
        active={isActive(forumKey)}
      >
        <FaChevronRight /> {getTitle(forumKey)}

      </MenuItem>
    })}
  </SubMenu>
}

